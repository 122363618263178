import { createSlice } from "@reduxjs/toolkit";
import { emailSignUpThunkV2 } from "./thunk/user/emailSignUpThunkV2";
import { googleSignUpThunkV2 } from "./thunk/user/googleSignUpThunkV2";
import { emailSignInThunkV2 } from "./thunk/user/emailSignInThunkV2";
import { logoutThunkV2 } from "./thunk/user/logoutThunkV2";
import { getUserDataThunkV2 } from "./thunk/user/getUserDataThunkV2";
import { saveUserDataThank } from "../slice/general/user/saveUserDataThank";

const userProfile = {
  moderation: {
    // moderation, approved, banned
    status: "moderation",

    historyChanges: [],
  },

  createdat: "",

  company: {
    ownersName: "",
    businessName: "",
    taxIdentificationNumber: "",
    businessLicense: "",
    localPermits: "",
    partnershipAgreement: "",

    articlesOfAssociation: "",
    operatingAgreement: "",

    //
    companyName: "",
    mailingAddress: "",
    timeZone: "",
    description: {
      descriptionOriginal: "",
    },
    historyChanges: [],
  },

  offerConfirm: "",

  rentalPointsIds: [],

  role: {
    currentRole: "user",
    historyChanges: [],
  },

  stateProfile: {
    currentState: "new",
    historyChanges: [],
  },

  files: {
    businessLicenseFiles: [],
    localPermitsFiles: [],
    articlesOfAssociationFiles: [],
  },

  user: {
    files: {
      avatarFiles: [],
    },
    contactingTheUser: "",
    gender: "",
    yourGender: "",
    ownerName: "",
    ownerSurname: "",
  },

  settings: {
    language: "en",
  },

  userContacts: {
    email: "",
    phoneNumber: "",
    historyChanges: [],
  },
  providerData: {},
  id: "",
};

const userSliceV2 = createSlice({
  name: "reducersToolkit/userSliceV2",

  initialState: {
    isLoading: false,
    error: null,

    currentUser: {},
    newUser: { ...userProfile },
  },

  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setResetError(state, action) {
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(emailSignUpThunkV2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailSignUpThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = action.payload.currentUser;
        state.error = action.payload.errorMessage;
      })
      .addCase(emailSignUpThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errorMessage;
      })

      .addCase(emailSignInThunkV2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailSignInThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;

        state.currentUser = action.payload.currentUser;
        state.error = null;
      })
      .addCase(emailSignInThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errorMessage;
      })

      .addCase(googleSignUpThunkV2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(googleSignUpThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = action.payload.currentUser;
        state.error = null;
      })
      .addCase(googleSignUpThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errorMessage;
      })

      .addCase(logoutThunkV2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logoutThunkV2.fulfilled, (state) => {
        state.isLoading = false;
        state.currentUser = null;
        state.error = null;
      })
      .addCase(logoutThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.errorMessage;
      })

      /**
       *  Перенести в catlogSlice
       **/

      .addCase(getUserDataThunkV2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserDataThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = action.payload.currentUser;
        state.newUser = action.payload.currentUser.user;
        state.company = action.payload.currentUser.company;
        state.userContacts = action.payload.currentUser.userContacts;
        state.error = null;
      })
      .addCase(getUserDataThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errorMessage;
      })

      .addCase(saveUserDataThank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveUserDataThank.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = { ...state.currentUser, ...action.payload };
        state.error = null;
      })
      .addCase(saveUserDataThank.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.errorMessage || "Error saving user data";
      });
  },
});

export const { setCurrentUser, setResetError } = userSliceV2.actions;

export default userSliceV2.reducer;
