import React, { useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowAditionalServiceDetail from "./WindowAditionalServiceDetail";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { useDispatch } from "react-redux";
import WindowAditionalServiceDetailModeration from "../Moderation/WindowAditionalServiceDetailModeration";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/catalogSliceV4";

const WidgetAditionalServicesList = ({ mode, additionalServices }) => {
  const dispatch = useDispatch();

  const [selectedAditionalService, setSelectedAditionalService] = useState({});

  const [
    isOpenWidgetEditAditionalService,
    setIsOpenWidgetEditAditionalService,
  ] = useState(false);

  const [
    isOpenWidgetEditAditionalServiceModeration,
    setIsOpenWidgetEditAditionalServiceModeration,
  ] = useState(false);

  const handleCloseWidgetEditAditionalService = () => {
    setIsOpenWidgetEditAditionalService(false);
    setIsOpenWidgetEditAditionalServiceModeration(false);
  };

  const handleToggleWidgetEditAditionalService = (additionalServiceItem) => {
    if (mode === "new") {
      setSelectedAditionalService(additionalServiceItem);
      setIsOpenWidgetEditAditionalService(true);
    }
    if (mode === "edit") {
      setSelectedAditionalService(additionalServiceItem);
      setIsOpenWidgetEditAditionalService(true);
    }
    if (mode === "moderation") {
      setSelectedAditionalService(additionalServiceItem);
      setIsOpenWidgetEditAditionalServiceModeration(true);
    }
    if (mode === "moderatorAdded") {
      setSelectedAditionalService(additionalServiceItem);
      setIsOpenWidgetEditAditionalServiceModeration(true);
      dispatch(
        setTempData({
          tempCurrentDocumentState:
            "rentalPoint_moderation_selectedAdditionalService",
          data: additionalServiceItem,
        })
      );
    }
  };

  return (
    <>
      <PopupDirrectory
        isOpen={isOpenWidgetEditAditionalService}
        onClose={handleCloseWidgetEditAditionalService}
        title="Edit aditional services"
      >
        <WindowAditionalServiceDetail
          mode={mode}
          selectedAditionalService={selectedAditionalService}
          onClose={handleCloseWidgetEditAditionalService}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenWidgetEditAditionalServiceModeration}
        onClose={handleCloseWidgetEditAditionalService}
        title="Edit aditional services moderation"
      >
        <WindowAditionalServiceDetailModeration
          mode={mode}
          selectedAditionalService={selectedAditionalService}
          onClose={handleCloseWidgetEditAditionalService}
        />
      </PopupDirrectory>
      {additionalServices?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {additionalServices?.map((additionalServiceItem, index) => (
            <div
              key={index}
              onClick={() =>
                handleToggleWidgetEditAditionalService(additionalServiceItem)
              }
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                backgroundColor: "var(--color-gray-100)",
                ...(additionalServiceItem.remove > 0
                  ? { opacity: 0.2 }
                  : { opacity: 1 }),

                borderRadius: "16px",
                padding: "16px 8px 16px 16px",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "70%",
                }}
              >
                <p>{additionalServiceItem?.title?.titleOriginal}</p>

                <p
                  className="p-light"
                  style={{
                    ...(additionalServiceItem?.counter > 0
                      ? {
                          color: "var(--color-black)",
                          fontWeight: "600",
                        }
                      : { color: "var(--color-gray-500)" }),
                  }}
                >
                  Available services:&nbsp;{additionalServiceItem?.counter || 0}
                  &nbsp;PC
                </p>

                <p
                  className="p-light"
                  style={{
                    width: "100%",
                  }}
                >
                  {additionalServiceItem?.description?.descriptionOriginal}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {formatCurrencyUSD(additionalServiceItem?.price)}
                </p>

                <span className="material-symbols-outlined">chevron_right</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default WidgetAditionalServicesList;
