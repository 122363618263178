import { createSlice } from "@reduxjs/toolkit";
import { fetchLocationDataThunkV4 } from "./thunk/catalog/V4/directoriesData/fetchLocationDataThunkV4";

const initialState = {
  locationsData: [],
  loadingLocations: false,
  errorLocations: null,
  lastUpdated: null,
};

const directoriesSliceV4 = createSlice({
  name: "directoriesSliceV4",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationDataThunkV4.pending, (state) => {
        state.loadingLocations = true;
        state.errorLocations = null;
      })
      .addCase(fetchLocationDataThunkV4.fulfilled, (state, action) => {
        if (!action.payload || action.payload.fromIndexedDB) {
          console.log(
            "Данные о локациях были получены из IndexedDB или отсутствуют."
          );
          state.loadingLocations = false;
          return;
        }

        state.loadingLocations = false;
        state.locationsData = action.payload.data;
        state.lastUpdated = action.payload.lastUpdated;
        console.log(
          "Состояние обновлено данными о локациях:",
          state.locationsData
        );
      })
      .addCase(fetchLocationDataThunkV4.rejected, (state, action) => {
        state.loadingLocations = false;
        state.errorLocations = action.payload;
        console.error(
          "Ошибка при обновлении состояния локаций:",
          state.errorLocations
        );
      });
  },
});

export const { reducer: directoriesReducer } = directoriesSliceV4;
export default directoriesSliceV4.reducer;
