import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { db } from "../../../../services/firebase/authFirebase";

export const updateFilePriorityThunkV4 = createAsyncThunk(
  "files/updateFilePriorityThunkV4",
  async (
    {
      rootCollectionPath,
      previewCollectionPath,
      metadataDocumentPath,
      idRootDocument,
      idPreviewDocument = null, // Если не передан, по умолчанию null
      fieldName,
      filesList,
      currentFile,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      loadingStateName,
      errorStateName,
    },
    { rejectWithValue }
  ) => {
    try {
      // Обновляем список файлов: устанавливаем приоритет для текущего файла и сбрасываем для остальных
      const updatedFiles = filesList.map((file) =>
        file.id === currentFile.id
          ? { ...file, priority: true }
          : { ...file, priority: false }
      );

      // 1. Обновляем файлы в корневом документе
      const rootDocRef = doc(
        db,
        ...rootCollectionPath.split("."),
        idRootDocument
      );

      // Подготавливаем данные для обновления
      const updateData = {
        [fieldName]: updatedFiles,
      };

      // Обновляем документ
      await updateDoc(rootDocRef, updateData);

      // Получаем обновленный документ
      const rootDocSnap = await getDoc(rootDocRef);
      if (!rootDocSnap.exists()) {
        throw new Error(
          `Документ root с ID ${idRootDocument} не существует после обновления.`
        );
      }
      const rootDocumentData = { idRootDocument, ...rootDocSnap.data() };

      // Поиск idPreviewDocument, если не передан
      if (!idPreviewDocument) {
        console.log("idPreviewDocument не передан, начинаем поиск...");

        const querySnapshot = await getDocs(
          collection(db, ...previewCollectionPath.split("."))
        );

        console.log(
          "Получены документы из previewCollectionPath:",
          previewCollectionPath
        );
        console.log("Количество документов в коллекции:", querySnapshot.size);

        for (const doc of querySnapshot.docs) {
          const data = doc.data().data || [];
          console.log(
            `Обрабатываем документ с ID: ${doc.id}, количество элементов в data: ${data.length}`
          );

          const foundItem = data.find(
            (item) => item.idRootDocument === idRootDocument
          );

          if (foundItem) {
            console.log(
              `Найден элемент с idRootDocument: ${idRootDocument} в документе с ID: ${doc.id}`
            );
            idPreviewDocument = doc.id;
            break;
          }
        }

        if (!idPreviewDocument) {
          throw new Error(
            `idPreviewDocument не найден для idRootDocument: ${idRootDocument}`
          );
        } else {
          console.log(`idPreviewDocument успешно найден: ${idPreviewDocument}`);
        }
      }

      // 2. Обновляем поле fileUrl в документе preview
      const previewDocRef = doc(
        db,
        ...previewCollectionPath.split("."),
        idPreviewDocument
      );

      const previewDocSnap = await getDoc(previewDocRef);
      if (!previewDocSnap.exists()) {
        throw new Error(
          `Документ preview с ID ${idPreviewDocument} не существует.`
        );
      }

      const previewData = previewDocSnap.data();
      const dataArray = previewData.data || [];

      // Находим элемент с нужным idRootDocument
      const itemIndex = dataArray.findIndex(
        (item) => item.idRootDocument === idRootDocument
      );

      if (itemIndex === -1) {
        throw new Error(
          `Элемент с idRootDocument ${idRootDocument} не найден в документе preview.`
        );
      }

      const itemToUpdate = dataArray[itemIndex];

      // Получаем URL файла с приоритетом
      const priorityFile = updatedFiles.find((file) => file.priority);

      if (priorityFile) {
        itemToUpdate.fileUrl = priorityFile.url;
      } else {
        itemToUpdate.fileUrl = "";
      }

      // Обновляем элемент в массиве data
      dataArray[itemIndex] = itemToUpdate;

      // Пересчитываем размер документа после обновления
      const newDocumentSize = new Blob([JSON.stringify({ data: dataArray })])
        .size;

      console.log("Новый размер документа:", newDocumentSize);

      // Обновляем документ preview
      await updateDoc(previewDocRef, {
        data: dataArray,
        documentSize: newDocumentSize,
      });

      // Формируем previewDocumentData с добавлением idPreviewDocument
      const previewDocumentData = { idPreviewDocument, ...itemToUpdate };

      // Обновление данных lastUpdatedDocument по пути metadataPath
      const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
      await setDoc(
        metadataDocRef,
        {
          lastUpdatedDocument: {
            lastUpdatedDocumentId: idPreviewDocument,
            documentSize: newDocumentSize,
          },
        },
        { merge: true }
      );

      console.log("Поле lastUpdatedDocument успешно обновлено:", {
        lastUpdatedDocumentId: idPreviewDocument,
        documentSize: newDocumentSize,
        dataArray,
      });

      // Возвращаем обновленные данные для обновления состояния
      return {
        rootDocumentData,
        previewDocumentData,
        rootCollectionPath,
        previewCollectionPath,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        loadingStateName,
        errorStateName,
      };
    } catch (error) {
      console.error("Error updating priority:", error);
      return rejectWithValue(error.message);
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const updateFilePriorityThunkV4 = createAsyncThunk(
//   "files/updateFilePriorityThunkV4",
//   async (
//     {
//       rootCollectionPath,
//       previewCollectionPath,
//       metadataDocumentPath,
//       idRootDocument,
//       idPreviewDocument,
//       fieldName,
//       filesList,
//       currentFile,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {

//     try {
//       // Обновляем список файлов: устанавливаем приоритет для текущего файла и сбрасываем для остальных
//       const updatedFiles = filesList.map((file) =>
//         file.id === currentFile.id
//           ? { ...file, priority: true }
//           : { ...file, priority: false }
//       );

//       // 1. Обновляем файлы в корневом документе
//       const rootDocRef = doc(
//         db,
//         ...rootCollectionPath.split("."),
//         idRootDocument
//       );

//       // Подготавливаем данные для обновления
//       const updateData = {
//         [fieldName]: updatedFiles,
//       };

//       // Обновляем документ
//       await updateDoc(rootDocRef, updateData);

//       // Получаем обновленный документ
//       const rootDocSnap = await getDoc(rootDocRef);
//       if (!rootDocSnap.exists()) {
//         throw new Error(
//           `Документ root с ID ${idRootDocument} не существует после обновления.`
//         );
//       }
//       const rootDocumentData = { idRootDocument, ...rootDocSnap.data() };

//       // 2. Обновляем поле fileUrl в документе preview
//       const previewDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idPreviewDocument
//       );

//       const previewDocSnap = await getDoc(previewDocRef);
//       if (!previewDocSnap.exists()) {
//         throw new Error(
//           `Документ preview с ID ${idPreviewDocument} не существует.`
//         );
//       }

//       const previewData = previewDocSnap.data();
//       const dataArray = previewData.data || [];

//       // Находим элемент с нужным idRootDocument
//       const itemIndex = dataArray.findIndex(
//         (item) => item.idRootDocument === idRootDocument
//       );

//       if (itemIndex === -1) {
//         throw new Error(
//           `Элемент с idRootDocument ${idRootDocument} не найден в документе preview.`
//         );
//       }

//       const itemToUpdate = dataArray[itemIndex];

//       // Получаем URL файла с приоритетом
//       const priorityFile = updatedFiles.find((file) => file.priority);

//       if (priorityFile) {
//         itemToUpdate.fileUrl = priorityFile.url;
//       } else {
//         itemToUpdate.fileUrl = "";
//       }

//       // Обновляем элемент в массиве data
//       dataArray[itemIndex] = itemToUpdate;

//       // Пересчитываем размер документа после обновления
//       const newDocumentSize = new Blob([JSON.stringify({ data: dataArray })])
//         .size;

//       console.log("Новый размер документа:", newDocumentSize);

//       // Обновляем документ preview
//       await updateDoc(previewDocRef, {
//         data: dataArray,
//         documentSize: newDocumentSize,
//       });

//       // Формируем previewDocumentData с добавлением idPreviewDocument
//       const previewDocumentData = { idPreviewDocument, ...itemToUpdate };

//       // Обновление данных lastUpdatedDocument по пути metadataPath
//       const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
//       await setDoc(
//         metadataDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idPreviewDocument,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       console.log("Поле lastUpdatedDocument успешно обновлено:", {
//         lastUpdatedDocumentId: idPreviewDocument,
//         documentSize: newDocumentSize,
//         dataArray,
//       });

//       // Возвращаем обновленные данные для обновления состояния
//       return {
//         rootDocumentData,
//         previewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         loadingStateName,
//         errorStateName,
//       };
//     } catch (error) {
//       console.error("Error updating priority:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );
