import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetUsersListModeration from "./WidgetUsersListModeration";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";

const WidgetModeration = ({ usersModeration, isLoading }) => {
  const [usersModerationList, setUsersModerationList] = useState(null);
  const [userState, setUserState] = useState("moderation");
  console.log("WidgetModeration usersModeration", usersModeration);
  useEffect(() => {
    if (usersModeration && Array.isArray(usersModeration)) {
      // Фильтрация списка пользователей по статусу
      const filteredUsers = usersModeration
        .filter((user) => user.status === userState)
        .sort((b, a) => new Date(a.lastUpdated) - new Date(b.lastUpdated)); // Сортировка от новых к старым

      setUsersModerationList(filteredUsers);
    }
  }, [userState, usersModeration]);

  return (
    <ContentBox isLoading={isLoading}>
      {usersModeration?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <SegmentedControls
            options={["moderation", "approved", "banned"]}
            onSelect="moderation"
            label="Partners in project"
            selected={setUserState}
          />
          <WidgetUsersListModeration
            usersModerationList={usersModerationList}
          />
        </div>
      )}
    </ContentBox>
  );
};

export default WidgetModeration;
