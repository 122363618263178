import React, { useCallback, useEffect, useState } from "react";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import InputText from "../WidgetsProfile/Inputs/InputText";
import AddData from "../WidgetsProfile/AddData/AddData";
import WidgetListFile from "../WidgetsProfile/UploadFiles/WidgetListFile";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowUploadFiles from "../DirectoryV3/Directory/WindowUploadFiles";
import Textarea from "../WidgetsProfile/Textarea/Textarea";

const WidgetPartnership = ({ company, setCompany, currentUser }) => {
  const [uId, setUid] = useState(null);
  const [businessLicenseFiles, setBusinessLicenseFiles] = useState([]);
  const [localPermitsFiles, setLocalPermitsFiles] = useState([]);
  console.log("WidgetSoleProprietorship uId", uId);
  const [
    windowUploadFilesBusinessLicense,
    setWindowUploadFilesBusinessLicense,
  ] = useState(false);
  const [windowUploadLocalPermitsChange, setWindowUploadLocalPermitsChange] =
    useState(false);

  const [iconBusinessLicense, setIconBusinessLicense] = useState(false);

  const [partnershipAgreement, setPartnershipAgreement] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState("");
  const [businessLicense, setBusinessLicense] = useState("");
  const [localPermits, setLocalPermits] = useState("");

  useEffect(() => {
    setUid(currentUser?.id || false);
  }, [currentUser]);

  useEffect(() => {
    setPartnershipAgreement(company?.partnershipAgreement || "");
    setBusinessName(company?.businessName || "");
    setTaxIdentificationNumber(company?.taxIdentificationNumber || "");
    setBusinessLicense(company?.businessLicense || "");
    setLocalPermits(company?.localPermits || "");
  }, [company]);

  // Обработчик изменения владельца
  const handlePartnershipAgreementChange = useCallback(
    (event) => {
      const partnershipAgreement = event.target.value;
      setPartnershipAgreement(partnershipAgreement);
      setCompany((prev) => ({
        ...prev,
        partnershipAgreement: partnershipAgreement,
      }));
    },
    [setCompany]
  );

  // Обработчик изменения бизнес-имени
  const handleBusinessNameChange = useCallback(
    (event) => {
      const newBusinessName = event.target.value;
      setBusinessName(newBusinessName);
      setCompany((prev) => ({
        ...prev,
        businessName: newBusinessName,
      }));
    },
    [setCompany]
  );

  // Обработчик изменения ИНН
  const handleTaxIdentificationNumberChange = useCallback(
    (event) => {
      const newTaxIdentificationNumber = event.target.value;
      setTaxIdentificationNumber(newTaxIdentificationNumber);
      setCompany((prev) => ({
        ...prev,
        taxIdentificationNumber: newTaxIdentificationNumber,
      }));
    },
    [setCompany]
  );

  // Обработчик изменения лицензии
  const handleBusinessLicenseChange = useCallback(
    (event) => {
      const newBusinessLicense = event.target.value;
      setBusinessLicense(newBusinessLicense);
      setCompany((prev) => ({
        ...prev,
        businessLicense: newBusinessLicense,
      }));
    },
    [setCompany]
  );

  // Обработчик изменения местных разрешений
  const handleLocalPermitsChange = useCallback(
    (event) => {
      const newLocalPermits = event.target.value;
      setLocalPermits(newLocalPermits);
      setCompany((prev) => ({
        ...prev,
        localPermits: newLocalPermits,
      }));
    },
    [setCompany]
  );

  const handleClose = () => {
    setWindowUploadFilesBusinessLicense(false);
    setWindowUploadLocalPermitsChange(false);
  };

  const handleOpenBusinessLicense = () => {
    setWindowUploadFilesBusinessLicense(true);
  };

  const handleOpenLocalPermitsChange = () => {
    setWindowUploadLocalPermitsChange(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <TitleBlockSection
          title="Sole proprietorship"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien, ac rhoncus justo magna non turpis. Sed tristique odio eu magna convallis interdum. Pellentesque vitae tortor est."
        />

        <InputText
          type="text"
          label="Partnership Agreement"
          value={partnershipAgreement}
          onChange={handlePartnershipAgreementChange}
        />
        <InputText
          type="text"
          label="Business Name"
          value={businessName}
          onChange={handleBusinessNameChange}
        />
        <InputText
          type="text"
          label="Tax Identification Number"
          value={taxIdentificationNumber}
          onChange={handleTaxIdentificationNumberChange}
        />
        <div
          style={{
            padding: "16px 0 16px 16px",
            borderLeft: "4px solid var(--color-gray-200)",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Textarea
            type="text"
            label="Business License"
            maxLength={1000}
            value={businessLicense}
            onChange={handleBusinessLicenseChange}
          />
          <AddData
            icon={
              iconBusinessLicense ? (
                <span className="material-symbols-outlined">edit</span>
              ) : (
                <span className="material-symbols-outlined">add</span>
              )
            }
            title="Business License files"
            description="Business License необходим для легального ведения бизнеса в определённой юрисдикции. Она подтверждает соответствие требованиям местных властей, обеспечивает соблюдение правил и стандартов, и помогает избежать юридических проблем."
            onClick={handleOpenBusinessLicense}
            onClose={handleClose}
          />

          <WidgetListFile
            collectionName={"users"}
            documentId={uId}
            fieldName={"files.businessLicenseFiles"}
            files={businessLicenseFiles}
            mode="edit"
          />
        </div>
        <div
          style={{
            padding: "16px 0 16px 16px",
            borderLeft: "4px solid var(--color-gray-200)",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Textarea
            type="text"
            label="Local Permits"
            maxLength={1000}
            value={localPermits}
            onChange={handleLocalPermitsChange}
          />
          <AddData
            icon={
              iconBusinessLicense ? (
                <span className="material-symbols-outlined">edit</span>
              ) : (
                <span className="material-symbols-outlined">add</span>
              )
            }
            title="Local Permits files"
            description="При регистрации бизнеса укажите местные разрешения, чтобы соблюсти все законодательные требования и избежать штрафов. Это поможет обеспечить законность деятельности и соответствие местным стандартам."
            onClick={handleOpenLocalPermitsChange}
            onClose={handleClose}
          />

          <WidgetListFile
            collectionName={"users"}
            documentId={uId}
            fieldName={"files.localPermitsFiles"}
            files={localPermitsFiles}
            mode="edit"
          />
        </div>
      </div>
      <PopupDirrectory
        isOpen={windowUploadFilesBusinessLicense}
        onClose={handleClose}
        title="Business License"
      >
        <WindowUploadFiles
          collectionName="users"
          documentId={uId}
          fieldName={"files.businessLicenseFiles"}
        />
      </PopupDirrectory>
      <PopupDirrectory
        isOpen={windowUploadLocalPermitsChange}
        onClose={handleClose}
        title="Local Permits Change"
      >
        <WindowUploadFiles
          collectionName="users"
          documentId={uId}
          fieldName={"files.localPermitsFiles"}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetPartnership;
