import React, { useEffect, useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import WindowUserDetailModeration from "./WindowUserDetailModeration";
import { useDispatch } from "react-redux";
import { setCurrentPreviewData } from "../../../../../reducersToolkit/catalogSliceV4";
import { v4 as uuidv4 } from "uuid";

const ItemPreviewUser = ({ onClick, user }) => {
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [avatarFile, setAvatarFile] = useState("");
  const [idRootDocument, setIdRootDocument] = useState(null);

  useEffect(() => {
    setAvatarFile(user?.fileUrl);
    setOwnerName(user?.ownerName);
    setEmail(user?.email);
    setPhoneNumber(user?.phoneNumber);
  }, [user]);

  return (
    <div
      onClick={onClick}
      style={{
        width: "100%",
        boxSizing: "border-box",
        minHeightheight: "56px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "var(--color-gray-100)",
        padding: "16px 8px 16px 16px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <AvatarUser size={60} url={avatarFile} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <h6>{ownerName || "No name"}</h6>
          <div>
            <p className="p-light">{email || "No email"}</p>
            <p className="p-light">{phoneNumber || "No phone number"}</p>
            {/* <p className="p-light">24.01.2024 11:00</p> */}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    </div>
  );
};

const WidgetUsersListModeration = ({ usersModerationList }) => {
  const dispatch = useDispatch();

  const [windowPartnerDetailModeration, setWindowPartnerDetailModeration] =
    useState(false);

  const handleClose = () => {
    setWindowPartnerDetailModeration(false);
  };

  /** Сохраняем выбраннй user preview */
  const setCurrenPreviewUser = (userItem) => {
    dispatch(
      setCurrentPreviewData({
        currentPreviewDocumenState:
          "user_moderation_selectedPreviewCurrentData",
        data: userItem,
      })
    );
    setWindowPartnerDetailModeration(true);
  };

  return (
    <>
      <PopupDirrectory
        isOpen={windowPartnerDetailModeration}
        onClose={handleClose}
        title="Partner detail Moderation"
      >
        <WindowUserDetailModeration onClose={handleClose} />
      </PopupDirrectory>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {usersModerationList?.map((userItem) => (
          <div
            key={uuidv4()}
            style={{
              width: "100%",
            }}
          >
            <ItemPreviewUser
              onClick={() => setCurrenPreviewUser(userItem)}
              user={userItem}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default WidgetUsersListModeration;
