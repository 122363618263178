import React, { useEffect, useState } from "react";
import "../profile.css";
import SubtitleBlockSection from "../WidgetsProfile/SubtitleBlockSection/SubtitleBlockSection";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Switcher from "../WidgetsProfile/Switcher/Switcher";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import Button from "../WidgetsProfile/Buttons/Button";
import WidgetUserAvatar from "./WidgetUserAvatar";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowUserProfile = ({ onClose }) => {
  const dispatch = useDispatch();
  const { loading, tempRootData, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [currentRootUser, setCurrentRootUser] = useState(null);

  const [acceptOffer, setAcceptOffer] = useState(false);
  const [uId, setUId] = useState(null);
  const [contactingTheUser, setContactingTheUser] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerSurname, setOwnerSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [yourGender, setYourGender] = useState("");
  const [descriptionCompany, setDescriptionCompany] = useState("");
  const [userAvatarFiles, setUserAvatarFiles] = useState([]);
  const [
    loading_user_auth_rootCurrentData,
    setLoading_user_auth_rootCurrentData,
  ] = useState(false);
  const [offerConfirm, setOfferConfirm] = useState("");

  useEffect(() => {
    setLoading_user_auth_rootCurrentData(
      loading?.loading_user_auth_rootCurrentData || false
    );
  }, [loading?.loading_user_auth_rootCurrentData]);

  useEffect(() => {
    setUserAvatarFiles(
      tempRootData?.user_auth_rootCurrentData?.files?.userAvatarFiles || []
    );
    setCurrentRootUser(tempRootData?.user_auth_rootCurrentData);

    setUId(tempRootData?.user_auth_rootCurrentData?.id);
    setContactingTheUser(
      tempRootData?.user_auth_rootCurrentData?.user?.contactingTheUser || ""
    );
    setOwnerName(
      tempRootData?.user_auth_rootCurrentData?.user?.ownerName || ""
    );
    setOwnerSurname(
      tempRootData?.user_auth_rootCurrentData?.user?.ownerSurname || ""
    );
    setGender(tempRootData?.user_auth_rootCurrentData?.user?.gender || "");
    setYourGender(
      tempRootData?.user_auth_rootCurrentData?.user?.yourGender || ""
    );
    setEmail(
      tempRootData?.user_auth_rootCurrentData?.userContacts?.email || ""
    );
    setPhoneNumber(
      tempRootData?.user_auth_rootCurrentData?.userContacts?.phoneNumber || ""
    );
    setCompanyName(
      tempRootData?.user_auth_rootCurrentData?.company?.companyName || ""
    );
    setMailingAddress(
      tempRootData?.user_auth_rootCurrentData?.company?.mailingAddress || ""
    );
    setTimeZone(
      tempRootData?.user_auth_rootCurrentData?.company?.timeZone || ""
    );
    setDescriptionCompany(
      tempRootData?.user_auth_rootCurrentData?.company?.description
        ?.descriptionOriginal || ""
    );
    setOfferConfirm(
      tempRootData?.user_auth_rootCurrentData?.offerConfirm || ""
    );
  }, [tempRootData.user_auth_rootCurrentData]);

  const handleUpdateFields = () => {
    const rootUserData = {
      ...currentRootUser,

      user: {
        contactingTheUser: contactingTheUser,
        gender: gender,
        yourGender: yourGender,
        ownerName: ownerName,
        ownerSurname: ownerSurname,
      },

      userContacts: {
        email: email,
        phoneNumber: phoneNumber,
      },

      company: {
        companyName: companyName,
        mailingAddress: mailingAddress,
        timeZone: timeZone,
        description: {
          descriptionOriginal: descriptionCompany,
        },
      },

      metaData: {
        offerConfirm: Date.now().toString(),
      },
    };

    const previewUserData = {
      ownerName: ownerName,
      email: email,
      phoneNumber: phoneNumber,
      fileUrl: userAvatarFiles.find((file) => file.priority === true),
    };
    try {
      dispatch(
        updateDataThunkV4({
          // Указываем id preview документа который будем менять
          idPreviewDocument: null,
          // Указываем id root Элемента
          idRootDocument: uId,
          // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
          // updatedData: rootCountryData,

          rootData: rootUserData,
          previewData: previewUserData,

          // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
          // newFiles: {
          //   userAvatarFiles: userAvatarNewFiles,
          //   /* Другие массивы файлов. Массивов файлов может быть несколько*/
          // },
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          previewCollectionPath: "usersPreview",
          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: "usersPreview.metadata",
          /*************/
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          // previewCurrentDocumenState: "user_profile_previewCurrentData",
          // Эти состояния будут отображать состояния загрузки и ошибки
          /*************/
          loadingStateName: "loading_update_auth_userRootCurrentData",
          errorSatetName: "currentError",
        })
      ).then((resultAction) => {
        // Проверяем успешное завершение updateDataThunkV4
        if (updateDataThunkV4.fulfilled.match(resultAction)) {
          // Второй dispatch только после успешного завершения первого
          dispatch(
            setCurrentRootData({
              currentPreviewDocumenState: "user_auth_rootCurrentData",
              data: rootUserData,
            })
          );
          onClose();
        } else {
          console.error("Ошибка при обновлении данных:", resultAction.error);
          onClose();
        }
      });
      onClose();
    } catch (error) {
      console.error("Ошибка вызова thunk:", error);
      onClose();
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      <ContentBox
        // isLoading={isLoading}
        position="start"
        label="User avatar"
      >
        <WidgetUserAvatar
          isLoading={loading_user_auth_rootCurrentData}
          currentRootUser={currentRootUser}
          collectionName="users"
          documentId={uId}
          fieldName="userAvatarFiles"
          size={160}
          iconAddphoto={true}
        />
        <InputText
          type="text"
          label="How can I call you?"
          description="Please enter your name as you would like to be addressed."
          value={contactingTheUser}
          onChange={(event) => setContactingTheUser(event.target.value)}
        />
      </ContentBox>

      <ContentBox
        // isLoading={isLoading}
        label="User contacts"
      >
        <SubtitleBlockSection
          title="User contacts"
          description="Contact information for quick communication with you. Please provide your email address and phone number. This information will be used for notifications and communication with you on important matters."
        />

        <InputText
          type="email"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <InputText
          type="text"
          label="Phone"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
      </ContentBox>

      <ContentBox
        // isLoading={isLoading}
        label="How can I call you?"
      >
        <SubtitleBlockSection
          title="How can I call you?"
          description="We use this data to send you important notifications and official messages."
        />

        <InputText
          type="text"
          label="Owner's name"
          value={ownerName}
          onChange={(event) => setOwnerName(event.target.value)}
        />

        <InputText
          type="text"
          label="Surname"
          value={ownerSurname}
          onChange={(event) => setOwnerSurname(event.target.value)}
        />

        <SegmentedControls
          options={["male", "female", "another"]}
          label="Your gender"
          description="Please specify your gender for accurate addressing in our notifications and messages. Your data will remain confidential and secure."
          selected={setGender}
          onSelect={gender}
        />

        {gender && gender === "another" && (
          <InputText
            type="text"
            label="Your gender"
            value={yourGender}
            onChange={(event) => setYourGender(event.target.value)}
          />
        )}
      </ContentBox>

      <ContentBox
        // isLoading={isLoading}
        // position="end"
        label="Company information"
      >
        <SubtitleBlockSection
          title="Company information"
          description="Please provide information about your company: name, mailing address, and select your timezone."
        />

        <InputText
          type="text"
          label="Company name"
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
        />

        {/* <DropdownList
          options={moment.tz.names()}
          onSelect={setTimeZone}
          label={`${timeZone ? timeZone : "Timezone"}`}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        /> */}

        <Textarea
          type="text"
          label="Description company"
          description="Please provide the address where you would like to receive official documents and notifications."
          value={descriptionCompany}
          onChange={(event) => setDescriptionCompany(event.target.value)}
          maxLength={2000}
        />

        <InputText
          type="text"
          label="Mailing address"
          description="Please provide the address where you would like to receive official documents and notifications."
          value={mailingAddress}
          onChange={(event) => setMailingAddress(event.target.value)}
        />

        <ButtonsBar>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Switcher
              isChecked={acceptOffer} // Используем состояние isOpen для определения состояния Switcher
              onChange={setAcceptOffer} // При изменении Switcher вызываем функцию для открытия/закрытия PopupDropdownList
              label="I confirm my agreement with the offer"
              // description="I confirm that I agree to the terms of the offer."
            />
            <div>
              <Button
                type="small"
                active={acceptOffer}
                label="Save"
                onClick={handleUpdateFields}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Please confirm that you want to update the user information.
                  </h1>
                }
              />
            </div>
          </div>
        </ButtonsBar>
      </ContentBox>
    </div>
  );
};

export default WindowUserProfile;
