// configureStore.js
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createIdbStorage from "redux-persist-indexeddb-storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import rentalPointsSliiceV2 from "../reducersToolkit/rentalPointsSliiceV2";
import bikesSliceV2 from "../reducersToolkit/bikesSliceV2";
import directorySliceV2 from "../reducersToolkit/directorySliceV2";
import moderationSliceV2 from "../reducersToolkit/moderationSliceV2";
import orderSliceV2 from "../reducersToolkit/orderSliceV2";
import navigateSliceV2 from "../reducersToolkit/navigateSliceV2";
import userSliceV2 from "../reducersToolkit/userSliceV2";
import catalogSliceV2 from "../reducersToolkit/catalogSliceV2";
import catalogSliceV4 from "../reducersToolkit/catalogSliceV4";
import rentalPointsSliiceV4 from "../reducersToolkit/rentalPointsSliiceV4";
import bikesSliceV4 from "../reducersToolkit/bikesSliceV4";
import directoriesSliceV4 from "../reducersToolkit/directoriesSliceV4";
// Комбинируем редьюсеры

const rootReducer = combineReducers({
  // rentalPointsSliiceV2,
  // rentalPointsSliiceV4,

  // bikesSliceV2,
  // bikesSliceV4,

  // directorySliceV2,
  // directoriesSliceV4,

  // moderationSliceV2,
  // orderSliceV2,
  navigateSliceV2,
  // userSliceV2,
  // catalogSliceV2,
  catalogSliceV4,

  // Добавьте остальные редьюсеры по мере необходимости
});

// Используем IndexedDB вместо localStorage
const persistConfig = {
  key: "root",
  storage: createIdbStorage({ name: "myApp", storeName: "reduxPersist" }), // IndexedDB
  whitelist: [
    // Укажите редьюсеры, которые нужно сохранять в IndexedDB
    // "rentalPointsSliiceV2",
    // "rentalPointsSliiceV4",
    // "bikesSliceV2",
    // "bikesSliceV4",
    // "directorySliceV2",
    // "directoriesSliceV4",
    // "moderationSliceV2",
    // "orderSliceV2",
    // "navigateSliceV2",
    // "userSliceV2",
    // "catalogSliceV2",
    "catalogSliceV4",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Создаем стор
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production", // Включение DevTools в режиме разработки
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // serializableCheck: false,
      },
    }),
});

// Создаем persistor
const persistor = persistStore(store);

export { store, persistor };
