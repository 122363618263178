import React, { useEffect, useState } from "react";
import WidgetLocationModeration from "./WidgetLocationModeration";
import WidgetRentalPointTimetable from "../RentalPointsV3/WidgetRentalPointTimetable";
import WidgetAditionalServices from "../RentalPointsV3/WidgetAditionalServices";
import WidgetRentalPointContact from "../RentalPointsV3/WidgetRentalPointContact";
import WidgetRentalPointBasicInformation from "../RentalPointsV3/WidgetRentalPointBasicInformation";
import WidgetRentalPointFilesBar from "../RentalPointsV3/WidgetRentalPointFilesBar";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import WidgetRentalPointModeration from "./WidgetRentalPointModeration";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import { fetchPreviewDataV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import { createDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/createDataThunkV4";

const WindowRentalPointDetailModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState({});

  const { tempRootData } = useSelector((state) => state.catalogSliceV4);

  const [basicInformation, setBasicInformation] = useState({});
  /** LOCATION */
  const [location, setLocation] = useState({});
  const [countries, setCountries] = useState(null);
  console.log(
    "WindowRentalPointDetailModeration countries",
    tempRootData.rentalPoint_create_createRerntalPoint
  );
  const [cities, setCities] = useState(null);
  /** */
  const [contacts, setContacts] = useState({});

  const [additionalServices, setAdditionalServices] = useState({});
  const [timetable, setTimetable] = useState([]);
  const [bikesList, setBikesList] = useState([]);
  const [currentRentalPoint, setCurrentRentalPoint] = useState({});

  const [rootRentalPointData, setRootRentalPointData] = useState(null);
  const [previewRentalPointData, setPreviewRentalPointData] = useState(null);
  // const { selectedUserRentalPoints, selectedUser, users, isLoadingBikes } =
  //   useSelector((state) => state.catalogSliceV4);

  const {
    // selectedRentalPointBikes,
    selectedRentalPoint,
    newRentalPoint,
  } = useSelector((state) => state.catalogSliceV4);

  const { brands, categories } = useSelector((state) => state.catalogSliceV4);

  // useEffect(() => {
  //   setUser(selectedUser);
  // }, [selectedUser]);

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: "countriesPreview",
        //
        loadingStateName: "loading_rentalPoint_countriesPreview",
        errorSatetName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "rentalPoint_countriesListPreview",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setBasicInformation(
        tempRootData?.rentalPoint_create_createRerntalPoint?.basicInformation ||
          {}
      );
      /** */
      setLocation(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location || {}
      );
      setCountries(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location?.country
          ?.title || ""
      );
      /** */
      setContacts(
        tempRootData?.rentalPoint_create_createRerntalPoint?.contacts || {}
      );
      setAdditionalServices(
        tempRootData?.rentalPoint_create_createRerntalPoint
          ?.additionalServices || {}
      );
      setTimetable(
        tempRootData?.rentalPoint_create_createRerntalPoint?.timetable || []
      );
      setCurrentRentalPoint(
        tempRootData?.rentalPoint_create_createRerntalPoint
      );

      setRootRentalPointData(
        tempRootData.rentalPoint_create_createRerntalPoint || null
      );

      setPreviewRentalPointData({
        title: "100",
        pointAddress: "100",
        iconUrl: "",
      });

      setBikesList([]);
    } else if (mode === "moderation") {
      // setBasicInformation(selectedRentalPoint.basicInformation);
      // setLocation(selectedRentalPoint.location);
      // setContacts(selectedRentalPoint.contacts);
      // setAdditionalServices(selectedRentalPoint.additionalServices);
      // setTimetable(selectedRentalPoint.timetable);
      // setCurrentRentalPoint(selectedRentalPoint);
      // setBikesList(selectedRentalPointBikes);
    }
  }, [
    mode,
    tempRootData?.rentalPoint_create_createRerntalPoint,
    // selectedRentalPoint,
    // selectedRentalPointBikes
  ]);

  const handleAddRentalPoint = (status) => {
    dispatch(
      createDataThunkV4({
        // Данные которые будут использоваться для создания коллекции в firestore
        // data: rootCountryData,
        rootData: rootRentalPointData,
        previewData: previewRentalPointData,
        // files: {
        //   // или другие массивы файлов, если есть
        //   iconFiles: newPhotoFiles,
        // },
        // Определяем пути для коллекций и метаданных с точками в качестве разделителей
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: "rentalPoints",
        previewCollectionPath: "rentalPointsPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: "rentalPointsPreview.metadata",
        // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
        // rootCurrentDocumentState: "cuurrentCountry",
        // Сохраняет в state МАССИВ объектов как результат выполнения thunk
        // previewCurrentDocumenState: "countriesPreview",
        // Название state который будет помогать отслежывать результаты работы thunk
        loadingStateName: "windowCountryDetailV4LoadingCreateCoutry",
        errorSatetName: "currentError",
      })
    )
      .unwrap()
      .then((response) => {
        console.log("rentalPoints успешно создан:", response);
        onClose();
      })
      .catch((error) => {
        console.error("Ошибка при создании rentalPoints:", error);
      });
    onClose();
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        {/* ------------- Rental point info ------------- */}
        <div className="block-section  block-section__start-box">
          <div className="profile-block-section__content-box">
            <WidgetRentalPointBasicInformation
              mode={mode}
              basicInformation={basicInformation}
            />
          </div>

          {mode !== "moderatorAdded" && (
            <WidgetRentalPointFilesBar
              selectedRentalPoint={selectedRentalPoint}
            />
          )}
        </div>

        {/* ------------- Moderation location ------------- */}
        <ContentBox>
          <WidgetLocationModeration
            location={location}
            countries={countries}
            cities={cities}
            ancorId="ancor1"
            mode={mode}
          />
        </ContentBox>
        {/* ------------- Bikes ------------- */}
        {mode !== "moderatorAdded" && (
          <ContentBox>
            <WidgetBikesList
              bikesList={bikesList}
              brands={brands}
              categories={categories}
              mode={mode}
            />
          </ContentBox>
        )}

        {/* ------------- Timetable ------------- */}
        <ContentBox>
          <WidgetRentalPointTimetable mode={mode} timetable={timetable} />
        </ContentBox>
        {/* ------------- Contact ------------- */}
        <ContentBox>
          <WidgetRentalPointContact mode={mode} contacts={contacts} />
        </ContentBox>
        {/* ------------- Aditional services ------------- */}
        <ContentBox>
          <WidgetAditionalServices
            mode={mode}
            additionalServices={additionalServices}
          />
        </ContentBox>

        <ContentBox>
          <WidgetRentalPointModeration
            mode={mode}
            // newRentalPoint={newRentalPoint}
            selectedRentalPoint={selectedRentalPoint}
            currentRentalPoint={currentRentalPoint}
            currentUser={user}
            onClose={onClose}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            label="Create rental point"
            color="--color-primary"
            active={true}
            onClick={() => handleAddRentalPoint("new")}
            allert={<div>Create</div>}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowRentalPointDetailModeration;
