import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
import { db } from "../../../../../services/firebase/authFirebase";
import { v4 as uuidv4 } from "uuid";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

export const createDataThunkV4 = createAsyncThunk(
  "locations/createDataThunkV4",
  async (
    {
      rootData,
      idRootDocument,
      previewData,
      files,
      rootCollectionPath,
      previewCollectionPath,
      metadataDocumentPath,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      loadingStateName,
      errorSatetName,
    },
    { rejectWithValue }
  ) => {
    try {
      // Если передан idRootDocument, то создаем ссылку на документ с этим ID, иначе генерируем новый ID
      const docRef = idRootDocument
        ? doc(db, ...rootCollectionPath.split("."), idRootDocument)
        : doc(collection(db, ...rootCollectionPath.split(".")));

      // Сохранение данных в Firestore без файлов
      await setDoc(docRef, rootData);

      // 2. Загрузка файлов в Firebase Storage и обновление документа в Firestore
      const uploadedFiles = {};

      // Обработка файлов по массивам (например: iconFiles, galleryFiles)
      if (files && Object.keys(files).length > 0) {
        for (const [fieldName, fileArray] of Object.entries(files)) {
          if (fileArray && fileArray.length > 0) {
            const uploaded = await uploadFilesToFirebase(
              `files/${docRef.id}/${fieldName}`,
              fileArray
            );

            // Преобразуем загруженные файлы в нужный формат
            const formattedFiles = uploaded.map((file) => ({
              url: file.url,
              name: file.name,
              priority: file.priority || false,
              id: uuidv4(),
            }));

            // Добавляем загруженные файлы в объект uploadedFiles с соответствующим именем поля
            uploadedFiles[fieldName] = formattedFiles;
          }
        }
      }

      // 3. Обновление документа в Firestore с ссылками на загруженные файлы
      const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
        acc.files = acc.files || {};
        acc.files[key] = uploadedFiles[key];
        return acc;
      }, {});

      if (Object.keys(updatedData).length > 0) {
        await updateDoc(docRef, updatedData);
      }

      // 4. Сохранение ID документа в newDataIds
      const languageDocRef = doc(db, ...metadataDocumentPath.split("."));

      await setDoc(
        languageDocRef,
        {
          newDataIds: arrayUnion(docRef.id),
        },
        { merge: true }
      );

      // 5. Чтение данных из поля lastUpdatedDocument
      const languageDocSnap = await getDoc(languageDocRef);

      let lastUpdatedDocId;
      let lastUpdatedDocSize;

      if (languageDocSnap.exists()) {
        const languageData = languageDocSnap.data();
        lastUpdatedDocSize =
          languageData.lastUpdatedDocument?.documentSize || 0;
        lastUpdatedDocId =
          languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
      } else {
        return rejectWithValue("Документ language не найден.");
      }

      // 6. Оценка размера объекта previewData
      const originalCollectionRef = collection(
        db,
        ...previewCollectionPath.split(".")
      );

      // Выбор нулевого элемента или элемента с флагом priority: true из iconFiles
      const selectedFile =
        uploadedFiles.iconFiles?.find((file) => file.priority) ||
        uploadedFiles.iconFiles?.[0] ||
        null;

      // Используем previewData, который был передан в качестве аргумента
      const previewDocumentData = {
        ...previewData,
        idRootDocument: docRef.id, // ID основного документа
        idPreviewDocument: "", // будет назначено позже
        fileUrl: selectedFile ? selectedFile.url : "", // Используем только выбранный файл
      };

      const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

      // 7. Определение документа для сохранения
      let newDocumentSize;
      let idDocumentToSave;

      if (lastUpdatedDocId && lastUpdatedDocSize + objectSize <= 1024 * 1024) {
        idDocumentToSave = lastUpdatedDocId;
        newDocumentSize = lastUpdatedDocSize + objectSize;
      } else {
        const newOriginalDocRef = doc(originalCollectionRef);
        idDocumentToSave = newOriginalDocRef.id;
        newDocumentSize = objectSize;
      }

      // 8. Обновление документа в original
      const originalDocRef = doc(originalCollectionRef, idDocumentToSave);
      previewDocumentData.idPreviewDocument = idDocumentToSave; // Назначаем ID для preview документа

      await setDoc(
        originalDocRef,
        {
          data: arrayUnion(previewDocumentData),
          documentSize: newDocumentSize,
        },
        { merge: true }
      );

      // 9. Обновление lastUpdatedDocument в документе language
      await setDoc(
        languageDocRef,
        {
          lastUpdatedDocument: {
            lastUpdatedDocumentId: idDocumentToSave,
            documentSize: newDocumentSize,
          },
        },
        { merge: true }
      );

      // Формируем возвращаемые данные для сохранения в срезе
      const rootDocumentData = {
        idRootDocument: docRef.id, // Сохраняется в срезе, но не в Firestore
        ...rootData,
        files: updatedData.files || {}, // Добавляем файлы
      };

      return {
        rootDocumentData, // Объект, содержащий данные основного документа (root), включая ID и файлы
        previewDocumentData, // Объект, содержащий данные документа предварительного просмотра (preview)
        rootCollectionPath, // Строка с путём до коллекции основного документа
        previewCollectionPath, // Строка с путём до коллекции документа предварительного просмотра
        rootCurrentDocumentState, // Строка, представляющая название состояния, связанного с основным документом в Redux срезе
        previewCurrentDocumenState, // Строка, представляющая название состояния, связанного с документом предварительного просмотра в Redux срезе
        loadingStateName, // Строка, представляющая название состояния загрузки (loading state) в Redux срезе
        errorSatetName, // Строка, представляющая название состояния ошибки (error state) в Redux срезе
      };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error("Ошибка при добавлении данных в Firestore:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   arrayUnion,
//   getDoc,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// export const createDataThunkV4 = createAsyncThunk(
//   "locations/createDataThunkV4",
//   async (
//     {
//       rootData,
//       idRootDocument,
//       previewData,
//       files,
//       rootCollectionPath,
//       previewCollectionPath,
//       metadataDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       loadingStateName,
//       errorSatetName,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // 1. Создание нового документа в Firestore
//       const docRef = doc(collection(db, ...rootCollectionPath.split(".")));

//       // Сохранение данных в Firestore без файлов
//       await setDoc(docRef, rootData);

//       // 2. Загрузка файлов в Firebase Storage и обновление документа в Firestore
//       const uploadedFiles = {};

//       // Обработка файлов по массивам (например: iconFiles, galleryFiles)
//       if (files && Object.keys(files).length > 0) {
//         for (const [fieldName, fileArray] of Object.entries(files)) {
//           if (fileArray && fileArray.length > 0) {
//             const uploaded = await uploadFilesToFirebase(
//               `files/${docRef.id}/${fieldName}`,
//               fileArray
//             );

//             // Преобразуем загруженные файлы в нужный формат
//             const formattedFiles = uploaded.map((file) => ({
//               url: file.url,
//               name: file.name,
//               priority: file.priority || false,
//               id: uuidv4(),
//             }));

//             // Добавляем загруженные файлы в объект uploadedFiles с соответствующим именем поля
//             uploadedFiles[fieldName] = formattedFiles;
//           }
//         }
//       }

//       // 3. Обновление документа в Firestore с ссылками на загруженные файлы
//       const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
//         acc.files = acc.files || {};
//         acc.files[key] = uploadedFiles[key];
//         return acc;
//       }, {});

//       if (Object.keys(updatedData).length > 0) {
//         await updateDoc(docRef, updatedData);
//       }

//       // 4. Сохранение ID документа в newDataIds
//       const languageDocRef = doc(db, ...metadataDocumentPath.split("."));

//       await setDoc(
//         languageDocRef,
//         {
//           newDataIds: arrayUnion(docRef.id),
//         },
//         { merge: true }
//       );

//       // 5. Чтение данных из поля lastUpdatedDocument
//       const languageDocSnap = await getDoc(languageDocRef);

//       let lastUpdatedDocId;
//       let lastUpdatedDocSize;

//       if (languageDocSnap.exists()) {
//         const languageData = languageDocSnap.data();
//         lastUpdatedDocSize =
//           languageData.lastUpdatedDocument?.documentSize || 0;
//         lastUpdatedDocId =
//           languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
//       } else {
//         return rejectWithValue("Документ language не найден.");
//       }

//       // 6. Оценка размера объекта previewData
//       const originalCollectionRef = collection(
//         db,
//         ...previewCollectionPath.split(".")
//       );

//       // Выбор нулевого элемента или элемента с флагом priority: true из iconFiles
//       const selectedFile =
//         uploadedFiles.iconFiles?.find((file) => file.priority) ||
//         uploadedFiles.iconFiles?.[0] ||
//         null;

//       // Используем previewData, который был передан в качестве аргумента
//       const previewDocumentData = {
//         ...previewData,
//         idRootDocument: docRef.id, // ID основного документа
//         idPreviewDocument: "", // будет назначено позже
//         fileUrl: selectedFile ? selectedFile.url : "", // Используем только выбранный файл
//       };

//       const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

//       // 7. Определение документа для сохранения
//       let newDocumentSize;
//       let idDocumentToSave;

//       if (lastUpdatedDocId && lastUpdatedDocSize + objectSize <= 1024 * 1024) {
//         idDocumentToSave = lastUpdatedDocId;
//         newDocumentSize = lastUpdatedDocSize + objectSize;
//       } else {
//         const newOriginalDocRef = doc(originalCollectionRef);
//         idDocumentToSave = newOriginalDocRef.id;
//         newDocumentSize = objectSize;
//       }

//       // 8. Обновление документа в original
//       const originalDocRef = doc(originalCollectionRef, idDocumentToSave);
//       previewDocumentData.idPreviewDocument = idDocumentToSave; // Назначаем ID для preview документа

//       await setDoc(
//         originalDocRef,
//         {
//           data: arrayUnion(previewDocumentData),
//           documentSize: newDocumentSize,
//         },
//         { merge: true }
//       );

//       // 9. Обновление lastUpdatedDocument в документе language
//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idDocumentToSave,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       // Формируем возвращаемые данные для сохранения в срезе
//       const rootDocumentData = {
//         idRootDocument: docRef.id, // Сохраняется в срезе, но не в Firestore
//         ...rootData,
//         files: updatedData.files || {}, // Добавляем файлы
//       };

//       return {
//         rootDocumentData,
//         previewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         loadingStateName,
//         errorSatetName,
//       };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error("Ошибка при добавлении данных в Firestore:", errorMessage);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   arrayUnion,
//   getDoc,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// export const createDataThunkV4 = createAsyncThunk(
//   "locations/createDataThunkV4",
//   async (
//     {
//       data,
//       files,
//       rootCollectionPath,
//       previewCollectionPath,
//       metadataDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       loadingStateName,
//       errorSatetName,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // 1. Создание нового документа в Firestore
//       const docRef = doc(collection(db, ...rootCollectionPath.split(".")));

//       // Сохранение данных в Firestore без файлов
//       await setDoc(docRef, data);

//       // 2. Загрузка файлов в Firebase Storage и обновление документа в Firestore
//       const uploadedFiles = {};

//       // Обработка файлов по массивам (например: iconFiles, galleryFiles)
//       if (files && Object.keys(files).length > 0) {
//         for (const [fieldName, fileArray] of Object.entries(files)) {
//           if (fileArray && fileArray.length > 0) {
//             const uploaded = await uploadFilesToFirebase(
//               `files/${docRef.id}/${fieldName}`,
//               fileArray
//             );

//             // Преобразуем загруженные файлы в нужный формат
//             const formattedFiles = uploaded.map((file) => ({
//               url: file.url,
//               name: file.name,
//               priority: file.priority || false,
//               id: uuidv4(),
//             }));

//             // Добавляем загруженные файлы в объект uploadedFiles с соответствующим именем поля
//             uploadedFiles[fieldName] = formattedFiles;
//           }
//         }
//       }

//       // 3. Обновление документа в Firestore с ссылками на загруженные файлы
//       const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
//         acc.files = acc.files || {};
//         acc.files[key] = uploadedFiles[key];
//         return acc;
//       }, {});

//       if (Object.keys(updatedData).length > 0) {
//         await updateDoc(docRef, updatedData);
//       }

//       // 4. Сохранение ID документа в newDataIds
//       const languageDocRef = doc(db, ...metadataDocumentPath.split(".")); // Применение split(".") для пути к документу

//       await setDoc(
//         languageDocRef,
//         {
//           newDataIds: arrayUnion(docRef.id),
//         },
//         { merge: true }
//       );

//       // 5. Чтение данных из поля lastUpdatedDocument
//       const languageDocSnap = await getDoc(languageDocRef);

//       let lastUpdatedDocId;
//       let lastUpdatedDocSize;

//       if (languageDocSnap.exists()) {
//         const languageData = languageDocSnap.data();
//         lastUpdatedDocSize =
//           languageData.lastUpdatedDocument?.documentSize || 0;
//         lastUpdatedDocId =
//           languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
//       } else {
//         return rejectWithValue("Документ language не найден.");
//       }

//       // 6. Оценка размера объекта originalObject
//       const originalCollectionRef = collection(
//         db,
//         ...previewCollectionPath.split(".")
//       );

//       // Выбор нулевого элемента или элемента с флагом priority: true из iconFiles
//       const selectedFile =
//         uploadedFiles.iconFiles?.find((file) => file.priority) ||
//         uploadedFiles.iconFiles?.[0] ||
//         null;

//       // Создаем объект originalObject, который сохраняется в Firestore
//       const originalObject = {
//         idRootDocument: docRef.id, // ID основного документа
//         idPreviewDocument: "", // будет назначено позже
//         title: data.title.titleOriginal || "",
//         description: (data.description.descriptionOriginal || "").slice(0, 250),
//         fileUrl: selectedFile ? selectedFile.url : "", // Используем только выбранный файл
//         createdat: data.createdat,
//       };

//       const objectSize = new Blob([JSON.stringify(originalObject)]).size;

//       // 7. Определение документа для сохранения
//       let newDocumentSize;
//       let idDocumentToSave;

//       if (lastUpdatedDocId && lastUpdatedDocSize + objectSize <= 1024 * 1024) {
//         idDocumentToSave = lastUpdatedDocId;
//         newDocumentSize = lastUpdatedDocSize + objectSize;
//       } else {
//         const newOriginalDocRef = doc(originalCollectionRef);
//         idDocumentToSave = newOriginalDocRef.id;
//         newDocumentSize = objectSize;
//       }

//       // 8. Обновление документа в original
//       const originalDocRef = doc(originalCollectionRef, idDocumentToSave);
//       originalObject.idPreviewDocument = idDocumentToSave; // Назначаем ID для preview документа

//       await setDoc(
//         originalDocRef,
//         {
//           data: arrayUnion(originalObject),
//           documentSize: newDocumentSize,
//         },
//         { merge: true }
//       );

//       // 9. Обновление lastUpdatedDocument в документе language
//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idDocumentToSave,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       // Формируем возвращаемые данные для сохранения в срезе
//       const rootDocumentData = {
//         idRootDocument: docRef.id, // Сохраняется в срезе, но не в Firestore
//         ...data,
//         files: updatedData.files || {}, // Добавляем файлы
//       };

//       const previewDocumentData = {
//         ...originalObject, // Включаем все необходимые поля, включая ID
//       };

//       return {
//         rootDocumentData,
//         previewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         loadingStateName,
//         errorSatetName,
//       };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error("Ошибка при добавлении данных в Firestore:", errorMessage);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
