import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AlertProvider } from "./components/UIElements/Alerts";
import ProtectedRoutes from "./services/route/protectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import WidgetPublicMenu from "./components/Public/Menu/WidgetPublicMenu";
import { LoadScript } from "@react-google-maps/api";
import { setLoadingStateGoogleMapApi } from "./slice/configSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Добавьте onAuthStateChanged
import { fetchRootDataThunkV4 } from "./reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapsLibraries = ["places"];

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();

  const { loading, error, tempRootData, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const { mainMenu } = useSelector((state) => state.navigateSliceV2);

  /** Stats */
  const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
  const [signInLoading, setSignInLoading] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [logOutLoading, setLogOutLoading] = useState(false);

  useEffect(() => {
    setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
  }, [tempRootData.user_auth_rootCurrentData]);

  useEffect(() => {
    setSignInLoading(loading?.loading_signIn || false);
    setSignUpLoading(loading?.loading_signUp || false);
    setLogOutLoading(loading?.loading_logOut || false);
  }, [loading.loading_signIn, loading.loading_signUp, loading.loading_logOut]);

  useEffect(() => {
    // Слушатель изменения состояния аутентификации
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        console.log("Слушатель изменения состояния аутентификации: ", user);
        // Пользователь аутентифицирован, обновляем данные пользователя
        dispatch(
          fetchRootDataThunkV4({
            idRootDocument: user.uid,
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: "users",
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState: "user_auth_rootCurrentData",
            // Название state который будет помогать отслежывать результаты работы thunk
            loadingStateName: "loading_fetchRootUser_App",
            errorSatetName: "addErrorRootuser",
          })
        )
          .unwrap()
          .then(({ rootDocumentData }) => {
            console.log("Текущий пользователь:", rootDocumentData);
          })
          .catch((error) => {
            console.error("Ошибка:", error);
            setUser_rootCurrentData(null);
          });
      }
    });

    // Очистка слушателя при размонтировании компонента
    return () => unsubscribe();
  }, [auth, dispatch]);

  return (
    <LoadScript
      googleMapsApiKey={googleAPIKey}
      libraries={googleMapsLibraries}
      onLoad={() => dispatch(setLoadingStateGoogleMapApi(true))}
    >
      <Router>
        <WidgetPublicMenu
          mainMenu={mainMenu}
          currentUser={user_auth_rootCurrentData}
          isLoading={signInLoading || signUpLoading || logOutLoading}
          error={error.error_logOut || error.error_signIn || error.error_signUp}
        />

        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoutes currentUser={user_auth_rootCurrentData} />
            }
          />
        </Routes>
      </Router>
    </LoadScript>
  );
}

export default App;
