import React, { useEffect, useState } from "react";
import AddData from "../../WidgetsProfile/AddData/AddData";
import InputText from "../../WidgetsProfile/Inputs/InputText";
import Textarea from "../../WidgetsProfile/Textarea/Textarea";
import Button from "../../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import WidgetCitiesListV4 from "./WidgetCitiesListV4";
import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";
import { createDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/createDataThunkV4";
import WindowCityDetailV4 from "../CitiesV4/WindowCityDetailV4";
import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
import { setCurrentRootData } from "../../../../../../reducersToolkit/catalogSliceV4";
import WidgetAlert from "../../WidgetsProfile/Alerts/WidgetAlert";
import { fetchRootDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { updateDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import { deleteDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/deleteDataThunkV4";
import { fetchPreviewDataV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";

const WindowCountryDetailV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const {
    countries,
    citiesPreview,
    loading,
    error,
    tempPreviewData,
    tempRootData,
  } = useSelector((state) => state.catalogSliceV4);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);
  const [newPhotoFiles, setNewPhotoFiles] = useState([]);

  const [windowCityDetail, setWindowCityDetail] = useState(false);
  const [citiesList, setCitiesList] = useState([]);

  const [originalTitle, setOriginalTitle] = useState("");
  const [originalDescription, setOriginalDescription] = useState("");

  //
  const [rootCountryId, setIdRootCountryId] = useState(null);
  const [previewCountryId, setpreviewCountryId] = useState(null);
  //

  // FETCH
  const [
    windowCountryDetailV4LoadingFetchPreviewCities,
    setWindowCountryDetailV4LoadingFetchPreviewCities,
  ] = useState(false);
  const [
    windowCountryDetailV4LoadingFetchRootCountries,
    setWindowCountryDetailV4LoadingFetchRootCountries,
  ] = useState(false);
  // CREATE
  const [
    windowCountryDetailV4LoadingCreateCoutry,
    setWindowCountryDetailV4LoadingCreateCoutry,
  ] = useState(false);
  const [
    windowCityDetailV4LoadingCreateCity,
    setWindowCityDetailV4LoadingCreateCity,
  ] = useState(false);
  // UPDATE
  const [
    windowCountryDetailV4LoadingUpdateCountry,
    setWindowCountryDetailV4LoadingUpdateCountry,
  ] = useState(false);
  const [
    windowCityDetailV4LoadingUpdateCity,
    setWindowCityDetailV4LoadingUpdateCity,
  ] = useState(false);
  const [
    windowCityDetailV4LoadingIconFiles,
    setWindowCityDetailV4LoadingIconFiles,
  ] = useState(false);
  // DELETE
  const [
    windowCountryDetailV4LoadingDeleteCountry,
    setWindowCountryDetailV4LoadingDeleteCountry,
  ] = useState(false);
  const [
    windowCityDetailV4LoadingDeleteCity,
    setWindowCityDetailV4LoadingDeleteCity,
  ] = useState(false);
  // LOADING
  const [
    windowCountryDetailV4loadingIconFiles,
    setWindowCountryDetailV4LoadingIconFiles,
  ] = useState(false);

  const [cuurrentCountry, setRootCurrentCountry] = useState({});

  useEffect(() => {
    // FETCH
    setWindowCountryDetailV4LoadingFetchPreviewCities(
      loading.windowCountryDetailV4LoadingFetchPreviewCities || false
    );
    setWindowCountryDetailV4LoadingFetchRootCountries(
      loading.windowCountryDetailV4LoadingFetchRootCountries || false
    );

    // CREATE
    setWindowCountryDetailV4LoadingCreateCoutry(
      loading.windowCountryDetailV4LoadingCreateCoutry || false
    );
    setWindowCityDetailV4LoadingCreateCity(
      loading.windowCityDetailV4LoadingCreateCity || false
    );

    // UPDATE
    setWindowCountryDetailV4LoadingUpdateCountry(
      loading.windowCountryDetailV4LoadingUpdateCountry || false
    );
    setWindowCityDetailV4LoadingUpdateCity(
      loading.windowCityDetailV4LoadingUpdateCity || false
    );
    setWindowCityDetailV4LoadingIconFiles(
      loading.windowCityDetailV4LoadingIconFiles || false
    );

    // DELETE
    setWindowCountryDetailV4LoadingDeleteCountry(
      loading.windowCountryDetailV4LoadingDeleteCountry || false
    );
    setWindowCityDetailV4LoadingDeleteCity(
      loading.windowCityDetailV4LoadingDeleteCity || false
    );
    // LOADING
    setWindowCountryDetailV4LoadingIconFiles(
      loading.windowCountryDetailV4loadingIconFiles || false
    );
  }, [
    loading.windowCountryDetailV4LoadingFetchPreviewCities,
    loading.windowCountryDetailV4LoadingFetchRootCountries,
    loading.windowCountryDetailV4LoadingCreateCoutry,
    loading.windowCityDetailV4LoadingCreateCity,
    loading.windowCountryDetailV4LoadingUpdateCountry,
    loading.windowCityDetailV4LoadingUpdateCity,
    loading.windowCityDetailV4LoadingIconFiles,
    loading.windowCountryDetailV4LoadingDeleteCountry,
    loading.windowCityDetailV4LoadingDeleteCity,
    loading.windowCountryDetailV4loadingIconFiles,
  ]);

  /*
CITIES
*/

  useEffect(() => {
    if (rootCountryId) {
      dispatch(
        fetchPreviewDataV4({
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          previewCollectionPath: `citiesPreview.countryIds.${rootCountryId}`,
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "windowCountryDetailV4LoadingFetchPreviewCities",
          errorSatetName: "windowCountryDetailV4PErrorFetchPreviewCities",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "previewCitiesList",
        })
      );
    }
  }, [
    dispatch,
    rootCountryId,
    windowCityDetailV4LoadingCreateCity,
    windowCityDetailV4LoadingUpdateCity,
    windowCityDetailV4LoadingDeleteCity,
    windowCityDetailV4LoadingIconFiles,
  ]);

  const handleOpenWindowCityDetail = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "rootCurrentCity",
        data: {},
      })
    );
    setWindowCityDetail(true);
  };

  /*
CITIES END
*/

  /*
COUNTRIES
*/

  useEffect(() => {
    setTitle(tempRootData?.cuurrentCountry?.title?.titleOriginal || "");
    setDescription(
      tempRootData?.cuurrentCountry?.description?.descriptionOriginal || ""
    );
    setOriginalTitle(tempRootData?.cuurrentCountry?.title?.titleOriginal || "");
    setOriginalDescription(
      tempRootData?.cuurrentCountry?.description?.descriptionOriginal || ""
    );
    setPreviewPhotoFiles(tempRootData?.cuurrentCountry?.files?.iconFiles || []);
    setRootCurrentCountry(tempRootData?.cuurrentCountry || {});
  }, [tempRootData?.cuurrentCountry]);

  useEffect(() => {
    setpreviewCountryId(
      tempPreviewData?.selectedPreviewCountry?.idPreviewDocument || null
    );
    setIdRootCountryId(
      tempPreviewData?.selectedPreviewCountry?.idRootDocument || null
    );
    setCitiesList(tempPreviewData?.previewCitiesList || []);
  }, [
    tempPreviewData.selectedPreviewCountry,
    tempPreviewData.previewCitiesList,
  ]);

  useEffect(() => {
    if (mode === "edit") {
      // Если есть idRootCountryId страны, выполняем запрос для получения данных для детализации
      if (rootCountryId) {
        console.log(
          `Есть idRootCountryId: ${rootCountryId} страны, выполняем запрос для получения данных для детализации`
        );
        dispatch(
          fetchRootDataThunkV4({
            idRootDocument: rootCountryId,
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: "countries",
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState: "cuurrentCountry",
            // Название state который будет помогать отслежывать результаты работы thunk
            loadingStateName: "windowCountryDetailV4LoadingFetchRootCountries",
            errorSatetName: "windowCountryDetailV4ErrorRootCountries",
          })
        );
      }
    } else if (mode === "new") {
      // Иначе просто обнуляем объект страны
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "cuurrentCountry",
          data: {},
        })
      );
    }
  }, [dispatch, rootCountryId, mode]);

  const handleAddDocument = async () => {
    try {
      if (mode === "new") {
        dispatch(
          createDataThunkV4({
            // Данные которые будут использоваться для создания коллекции в firestore
            // data: rootCountryData,
            rootData: rootCountryData,
            previewData: previewCountryData,
            files: {
              // или другие массивы файлов, если есть
              iconFiles: newPhotoFiles,
            },
            // Определяем пути для коллекций и метаданных с точками в качестве разделителей
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: "countries",
            previewCollectionPath: "countriesPreview",
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath: "countriesPreview.metadata",
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState: "cuurrentCountry",
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            previewCurrentDocumenState: "countriesPreview",
            // Название state который будет помогать отслежывать результаты работы thunk
            loadingStateName: "windowCountryDetailV4LoadingCreateCoutry",
            errorSatetName: "windowCountryDetailV4ErrorCreateCountry",
          })
        )
          .unwrap()
          .then((response) => {
            console.log("Страна успешно создана:", response);
            onClose();
          })
          .catch((error) => {
            console.error("Ошибка при создании страны:", error);
          });
        onClose();
      }

      if (mode === "edit") {
        if (previewCountryId && rootCountryId) {
          dispatch(
            updateDataThunkV4({
              // Указываем id preview документа который будем менять
              idPreviewDocument: previewCountryId,
              // Указываем id root Элемента
              idRootDocument: rootCountryId,
              // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
              // updatedData: rootCountryData,

              rootData: rootCountryData,
              previewData: previewCountryData,

              // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
              newFiles: {
                iconFiles: newPhotoFiles,
                /* Другие массивы файлов. Массивов файлов может быть несколько*/
              },
              // Путь до коллекции указывается из НЕ четного колличества вложенностей
              rootCollectionPath: "countries",
              previewCollectionPath: "countriesPreview",
              // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
              metadataDocumentPath: "countriesPreview.metadata",
              /*************/
              // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
              rootCurrentDocumentState: "cuurrentCountry",
              // Сохраняет в state МАССИВ объектов как результат выполнения thunk
              previewCurrentDocumenState: "countriesPreview",
              // Эти состояния будут отображать состояния загрузки и ошибки
              /*************/
              loadingStateName: "windowCountryDetailV4LoadingUpdateCountry",
              errorSatetName: "windowCountryDetailV4ErrorUpdateCouintry",
            })
          );
          onClose();
        }
      }
    } catch (error) {
      console.error("Ошибка при создании или обновлении страны:", error);
      onClose();
    }
  };

  const handleDeleDirectory = () => {
    dispatch(
      deleteDataThunkV4({
        // Id документов у которых поле remove будет обновлено
        idPreviewDocument: previewCountryId,
        idRootDocument: rootCountryId,
        // Определение путей коллекций
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: "countries",
        previewCollectionPath: "countriesPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: "countriesPreview.metadata",
        // Название state который будет содержать результат работы thunk
        previewCurrentDocumenState: "countriesPreview",
        // Название state который будет помогать отслежывать результаты работы thunk
        loadingStateName: "windowCountryDetailV4LoadingDeleteCountry",
        errorSatetName: "windowCountryDetailV4ErrorDeleteCountry",
      })
    );
    onClose();
  };

  /*
  COUNTRIES END
  */

  const rootCountryData = {
    ...cuurrentCountry,
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const previewCountryData = {
    title: title || "",
    description: (description || "").slice(0, 250),
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const handleClose = () => {
    setWindowCityDetail(false);
  };

  const handleSetName = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  const isButtonActive =
    newPhotoFiles.length > 0 ||
    title !== originalTitle ||
    description !== originalDescription;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          ...(mode === "new" && {
            marginBottom: "72px",
          }),
        }}
      >
        <ContentBox
          position="start"
          isLoading={windowCountryDetailV4LoadingFetchRootCountries}
        >
          <WidgetListFileV4
            isLoading={windowCountryDetailV4loadingIconFiles}
            // Передаём данные для отображения
            viewFiles={previewPhotoFiles}
            // Поле в котором находится массив с файлами
            fieldName="files.iconFiles"
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath="countries"
            previewCollectionPath="countriesPreview"
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath="countriesPreview.metadata"
            // Редактируемые документы
            idPreviewDocument={previewCountryId}
            idRootDocument={rootCountryId}
            // Динамические состояния загрузки
            loadingStateName="windowCountryDetailV4loadingIconFiles"
            errorSatetName="errorIconFiles"
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState="cuurrentCountry"
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            previewCurrentDocumenState="countriesPreview"
            // Режим
            mode="edit"
          />

          <WidgetUploadFilesV4
            isLoading={windowCountryDetailV4loadingIconFiles}
            id="iconFiles"
            title="Add photo country"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setNewPhotoFiles}
          />

          <InputText
            id="Name"
            label="Country"
            description="Enter the name of the country."
            value={title}
            onChange={handleSetName}
            type="text"
          />

          <Textarea
            label="Description"
            maxLength={5000}
            value={description}
            onChange={handleSetDescription}
          />

          {mode === "edit" && (
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />

              <Button
                type="small"
                label="Delete"
                active={true}
                onClick={() => handleDeleDirectory()}
                color="--color-alarm"
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />

              <Button
                type="small"
                label="Save"
                active={isButtonActive}
                onClick={() => handleAddDocument()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />
            </div>
          )}
        </ContentBox>

        {mode === "new" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />

              <Button
                type="small"
                label="Save"
                active={title !== "" && description !== ""}
                onClick={() => handleAddDocument()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}

        {mode === "edit" && (
          <ContentBox
            position="end"
            isLoading={
              windowCityDetailV4LoadingCreateCity ||
              windowCityDetailV4LoadingUpdateCity ||
              windowCityDetailV4LoadingDeleteCity ||
              windowCityDetailV4LoadingIconFiles
            }
          >
            <AddData
              isLoading={
                windowCityDetailV4LoadingCreateCity ||
                windowCityDetailV4LoadingUpdateCity ||
                windowCityDetailV4LoadingDeleteCity ||
                windowCityDetailV4LoadingIconFiles
              }
              onClick={handleOpenWindowCityDetail}
              icon={<span className="material-symbols-outlined">add</span>}
              title="Add city"
              description="Manage the files associated with this country."
            />
            <WidgetCitiesListV4
              isLoading={
                windowCityDetailV4LoadingCreateCity ||
                windowCityDetailV4LoadingUpdateCity ||
                windowCityDetailV4LoadingDeleteCity ||
                windowCityDetailV4LoadingIconFiles
              }
              cities={citiesList}
            />
          </ContentBox>
        )}
      </div>

      <PopupDirrectory
        isOpen={windowCityDetail}
        onClose={handleClose}
        title={`Add city V4`}
      >
        <WindowCityDetailV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default WindowCountryDetailV4;
