import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

export const deleteDataThunkV4 = createAsyncThunk(
  "countries/deleteDataThunkV4",
  async (
    {
      idPreviewDocument,
      idRootDocument,
      rootCollectionPath,
      previewCollectionPath,
      metadataDocumentPath,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      loadingStateName,
      errorSatetName,
    },
    { rejectWithValue }
  ) => {
    try {
      // 1. Получаем документ в корневой коллекции
      const rootDocRef = doc(
        db,
        ...rootCollectionPath.split("."),
        idRootDocument
      );
      const rootDocSnap = await getDoc(rootDocRef);

      if (!rootDocSnap.exists()) {
        throw new Error(
          `Документ с ID ${idRootDocument} не найден в коллекции ${rootCollectionPath}`
        );
      }

      // 2. Обновляем поле remove текущим timestamp в корневом документе
      const timestamp = Date.now(); // Текущий timestamp
      await updateDoc(rootDocRef, { remove: timestamp });

      // Получаем обновленные данные rootDocumentData
      const rootDocumentData = {
        ...rootDocSnap.data(),
        remove: timestamp,
        idRootDocument: idRootDocument,
      };

      // 3. Получаем документ в коллекции предварительного просмотра
      const previewDocRef = doc(
        db,
        ...previewCollectionPath.split("."),
        idPreviewDocument
      );
      const previewDocSnap = await getDoc(previewDocRef);

      if (!previewDocSnap.exists()) {
        throw new Error(
          `Документ с ID ${idPreviewDocument} не найден в коллекции ${previewCollectionPath}`
        );
      }

      // 4. Находим объект в массиве data, соответствующий idRootDocument
      const previewData = previewDocSnap.data();
      const previewDataArray = previewData.data || [];

      const itemIndex = previewDataArray.findIndex(
        (item) => item.idRootDocument === idRootDocument
      );

      if (itemIndex === -1) {
        throw new Error(
          `Объект с idRootDocument ${idRootDocument} не найден в массиве data документа ${idPreviewDocument}`
        );
      }

      // 5. Обновляем поле remove в найденном объекте
      previewDataArray[itemIndex].remove = timestamp;

      // Сохраняем изменения в документе коллекции предварительного просмотра
      await updateDoc(previewDocRef, { data: previewDataArray });

      // Получаем обновленные данные previewDocumentData
      const previewDocumentData = previewDataArray[itemIndex];

      // 6. Удаляем idRootDocument из newDataIds
      const languageDocRef = doc(db, ...metadataDocumentPath.split("."));

      await updateDoc(languageDocRef, {
        newDataIds: arrayRemove(idRootDocument),
      });

      // 7. Добавляем idRootDocument в deletedDataIds
      await updateDoc(languageDocRef, {
        deletedDataIds: arrayUnion(idRootDocument),
      });

      // 8. Обновление lastUpdatedDocument в документе language
      const languageDocSnap = await getDoc(languageDocRef);

      let lastUpdatedDocId;
      let lastUpdatedDocSize;

      if (languageDocSnap.exists()) {
        const languageData = languageDocSnap.data();
        lastUpdatedDocSize =
          languageData.lastUpdatedDocument?.documentSize || 0;
        lastUpdatedDocId =
          languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
      } else {
        console.error("Ошибка: Документ language не найден.");
        return rejectWithValue("Документ language не найден.");
      }

      // 9. Вычисление нового размера документа и обновление lastUpdatedDocument
      const removedItemSize = new Blob([
        JSON.stringify(previewDataArray[itemIndex]),
      ]).size;

      // Уменьшаем размер на размер удаленного элемента
      const newDocumentSize = lastUpdatedDocSize - removedItemSize;

      await setDoc(
        languageDocRef,
        {
          lastUpdatedDocument: {
            lastUpdatedDocumentId: lastUpdatedDocId,
            documentSize: newDocumentSize,
          },
        },
        { merge: true }
      );

      return {
        rootDocumentData, // Объект, содержащий данные основного документа (root) после удаления
        previewDocumentData, // Объект, содержащий данные документа предварительного просмотра (preview) после удаления
        rootCollectionPath, // Строка с путём до коллекции основного документа
        previewCollectionPath, // Строка с путём до коллекции документа предварительного просмотра
        metadataDocumentPath, // Строка с путём до документа метаданных
        rootCurrentDocumentState, // Строка, представляющая название состояния, связанного с основным документом в Redux срезе
        previewCurrentDocumenState, // Строка, представляющая название состояния, связанного с документом предварительного просмотра в Redux срезе
        loadingStateName, // Строка, представляющая название состояния загрузки (loading state) в Redux срезе
        errorSatetName, // Строка, представляющая название состояния ошибки (error state) в Redux срезе
      };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error("Ошибка при удалении страны:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);
