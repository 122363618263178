import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetUserAvatar from "../UserProfile/WidgetUserAvatar";
import InputText from "../WidgetsProfile/Inputs/InputText";
import SubtitleBlockSection from "../WidgetsProfile/SubtitleBlockSection/SubtitleBlockSection";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowUserProfileModeration = ({ onClose }) => {
  const dispatch = useDispatch();

  const { loading, tempRootData, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  /** USER DATA */
  // const [rootCcurrentUser, setRootCurrentUser] = useState(null);
  // const [acceptOffer, setAcceptOffer] = useState(false);
  // const [uId, setUId] = useState(null);
  const [contactingTheUser, setContactingTheUser] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerSurname, setOwnerSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [yourGender, setYourGender] = useState("");
  const [descriptionCompany, setDescriptionCompany] = useState("");

  const [idPreviewDocument, setIdPreviewDocument] = useState(null);
  const [idRootDocument, setIdRootDocument] = useState(null);

  const [currentRootUser, setCurrentRootUser] = useState(null);
  const [currentPreviewUser, setCurrentPreviewUser] = useState(null);

  const [initialData, setInitialData] = useState({});
  const [isModified, setIsModified] = useState(false); // Для отслеживания изменений

  /** LOADING */
  const [loading_fetchRootUserData, setLoading_fetchRootUserData] =
    useState(false);

  const [
    loading_moderation_uploadFilesRootCurrentData,
    setLoading_moderation_uploadFilesRootCurrentData,
  ] = useState(false);
  /** */

  // Когда загружаются данные из tempRootData
  useEffect(() => {
    const userData = tempRootData?.user_moderation_rootCurrentData || {};

    // Установка начальных значений
    const initialValues = {
      contactingTheUser: userData?.user?.contactingTheUser || "",
      ownerName: userData?.user?.ownerName || "",
      ownerSurname: userData?.user?.ownerSurname || "",
      email: userData?.userContacts?.email || "",
      phoneNumber: userData?.userContacts?.phoneNumber || "",
      gender: userData?.user?.gender || "",
      yourGender: userData?.user?.yourGender || "",
      companyName: userData?.company?.companyName || "",
      mailingAddress: userData?.company?.mailingAddress || "",
      descriptionCompany:
        userData?.company?.description?.descriptionOriginal || "",
    };

    setInitialData(initialValues);
    setContactingTheUser(initialValues.contactingTheUser);
    setOwnerName(initialValues.ownerName);
    setOwnerSurname(initialValues.ownerSurname);
    setEmail(initialValues.email);
    setPhoneNumber(initialValues.phoneNumber);
    setGender(initialValues.gender);
    setYourGender(initialValues.yourGender);
    setCompanyName(initialValues.companyName);
    setMailingAddress(initialValues.mailingAddress);
    setDescriptionCompany(initialValues.descriptionCompany);

    setIdRootDocument(userData?.id);
  }, [tempRootData]);

  // Проверка изменений в каждом поле
  useEffect(() => {
    const hasChanges =
      contactingTheUser !== initialData.contactingTheUser ||
      ownerName !== initialData.ownerName ||
      ownerSurname !== initialData.ownerSurname ||
      email !== initialData.email ||
      phoneNumber !== initialData.phoneNumber ||
      gender !== initialData.gender ||
      yourGender !== initialData.yourGender ||
      companyName !== initialData.companyName ||
      mailingAddress !== initialData.mailingAddress ||
      descriptionCompany !== initialData.descriptionCompany;

    setIsModified(hasChanges); // Если есть изменения, кнопка станет активной
  }, [
    contactingTheUser,
    ownerName,
    ownerSurname,
    email,
    phoneNumber,
    gender,
    yourGender,
    companyName,
    mailingAddress,
    descriptionCompany,
    initialData,
  ]);

  /** ROOT */
  useEffect(() => {
    setCurrentRootUser(tempRootData?.user_moderation_rootCurrentData);

    setContactingTheUser(
      tempRootData?.user_moderation_rootCurrentData?.user?.contactingTheUser ||
        ""
    );

    setOwnerName(
      tempRootData?.user_moderation_rootCurrentData?.user?.ownerName || ""
    );

    setOwnerSurname(
      tempRootData?.user_moderation_rootCurrentData?.user?.ownerSurname || ""
    );

    setGender(
      tempRootData?.user_moderation_rootCurrentData?.user?.gender || ""
    );

    setYourGender(
      tempRootData?.user_moderation_rootCurrentData?.user?.yourGender || ""
    );

    setEmail(
      tempRootData?.user_moderation_rootCurrentData?.userContacts?.email || ""
    );

    setPhoneNumber(
      tempRootData?.user_moderation_rootCurrentData?.userContacts
        ?.phoneNumber || ""
    );

    setCompanyName(
      tempRootData?.user_moderation_rootCurrentData?.company?.companyName || ""
    );

    setMailingAddress(
      tempRootData?.user_moderation_rootCurrentData?.company?.mailingAddress ||
        ""
    );

    setDescriptionCompany(
      tempRootData?.user_moderation_rootCurrentData?.company?.description
        ?.descriptionOriginal || ""
    );
  }, [tempRootData?.user_moderation_rootCurrentData]);
  /** */

  /** PREVIEW */
  useEffect(() => {
    setCurrentPreviewUser(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData || null
    );

    setIdPreviewDocument(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.idPreviewDocument || null
    );

    setIdRootDocument(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.idRootDocument || null
    );
  }, [
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
    tempPreviewData?.user_moderation_selectedPreviewCurrentData
      ?.idPreviewDocument,
    tempPreviewData?.user_moderation_selectedPreviewCurrentData?.idRootDocument,
  ]);
  /** */

  /** LOADING */
  useEffect(() => {
    setLoading_fetchRootUserData(loading?.loading_fetchRootUserData || false);

    setLoading_moderation_uploadFilesRootCurrentData(
      loading?.loading_moderation_uploadFilesRootCurrentData || false
    );
  }, [
    loading.loading_fetchRootUserData,
    loading?.loading_moderation_uploadFilesRootCurrentData,
  ]);
  /** */

  useEffect(() => {
    // Если есть rootUserId, выполняем запрос для получения данных для детализации
    if (idRootDocument) {
      console.log(
        `Есть rootUserId: ${idRootDocument} выполняем запрос для получения данных для детализации`
      );
      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: idRootDocument,
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_moderation_rootCurrentData",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_fetchRootUserData",
          errorSatetName: "error_fetchUserRootData",
        })
      );
    }
  }, [dispatch, idRootDocument]);

  const handleUpdateFields = () => {
    const rootData = {
      ...currentRootUser,
      user: {
        contactingTheUser: contactingTheUser,
        ownerName: ownerName,
        ownerSurname: ownerSurname,
        gender: gender,
        yourGender: yourGender,
      },
      userContacts: {
        email: email,
        phoneNumber: phoneNumber,
      },

      company: {
        companyName: companyName,
        mailingAddress: mailingAddress,
        description: {
          descriptionOriginal: descriptionCompany,
        },
      },

      offerConfirm: Date.now().toString(),
    };

    const previewData = {
      ...currentPreviewUser,
      ownerName: ownerName,
      email: email,
      phoneNumber: phoneNumber,
    };

    dispatch(
      updateDataThunkV4({
        // Указываем id preview документа который будем менять
        idPreviewDocument: null,
        // Указываем id root Элемента
        idRootDocument: idRootDocument,
        // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
        // updatedData: rootCountryData,

        rootData: rootData,
        previewData: previewData,

        // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
        // newFiles: {
        //   iconFiles: newPhotoFiles,
        //   /* Другие массивы файлов. Массивов файлов может быть несколько*/
        // },
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: "users",
        previewCollectionPath: "usersPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: "usersPreview.metadata",
        // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
        rootCurrentDocumentState: "user_moderation_rootCurrentData",
        // Сохраняет в state МАССИВ объектов как результат выполнения thunk
        // previewCurrentDocumenState:
        //   "user_moderation_selectedPreviewCurrentData",
        // Эти состояния будут отображать состояния загрузки и ошибки
        loadingStateName: "loading_moderation_rootUserCurrentData",
        errorSatetName: "error_moderation_rootCurrentData",
      })
    );
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        marginBottom: "72PX",
      }}
    >
      <ContentBox
        isLoading={loading_fetchRootUserData}
        position="start"
        label="User avatar"
      >
        <WidgetUserAvatar
          isLoading={loading_moderation_uploadFilesRootCurrentData}
          // collectionName="users"
          // documentId={idRootDocument}
          currentRootUser={currentRootUser}
          fieldName="userAvatarFiles"
          size={160}
          iconAddphoto={true}
          mode="moderation"
        />

        <InputText
          type="text"
          label="How can I call you?"
          description="Please enter your name as you would like to be addressed."
          value={contactingTheUser}
          onChange={(event) => setContactingTheUser(event.target.value)}
        />
      </ContentBox>

      <ContentBox isLoading={loading_fetchRootUserData} label="User contacts">
        <SubtitleBlockSection
          title="User contacts"
          description="Contact information for quick communication with you. Please provide your email address and phone number. This information will be used for notifications and communication with you on important matters."
        />

        <InputText
          type="email"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <InputText
          type="text"
          label="Phone"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
      </ContentBox>

      <ContentBox
        isLoading={loading_fetchRootUserData}
        label="How can I call you?"
      >
        <SubtitleBlockSection
          title="How can I call you?"
          description="We use this data to send you important notifications and official messages."
        />

        <InputText
          type="text"
          label="Owner's name"
          value={ownerName}
          onChange={(event) => setOwnerName(event.target.value)}
        />

        <InputText
          type="text"
          label="Surname"
          value={ownerSurname}
          onChange={(event) => setOwnerSurname(event.target.value)}
        />

        <SegmentedControls
          options={["male", "female", "another"]}
          label="Your gender"
          description="Please specify your gender for accurate addressing in our notifications and messages. Your data will remain confidential and secure."
          selected={setGender}
          onSelect={gender}
        />

        {gender && gender === "another" && (
          <InputText
            type="text"
            label="Your gender"
            value={yourGender}
            onChange={(event) => setYourGender(event.target.value)}
          />
        )}
      </ContentBox>

      <ContentBox
        isLoading={loading_fetchRootUserData}
        // position="end"
        label="Company information"
      >
        <SubtitleBlockSection
          title="Company information"
          description="Please provide information about your company: name, mailing address, and select your timezone."
        />

        <InputText
          type="text"
          label="Company name"
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
        />

        <Textarea
          type="text"
          label="Description company"
          description="Please provide the address where you would like to receive official documents and notifications."
          value={descriptionCompany}
          onChange={(event) => setDescriptionCompany(event.target.value)}
          maxLength={2000}
        />

        <InputText
          type="text"
          label="Mailing address"
          description="Please provide the address where you would like to receive official documents and notifications."
          value={mailingAddress}
          onChange={(event) => setMailingAddress(event.target.value)}
        />

        <ButtonsBar>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
              width: "100%",
            }}
          >
            <Button
              type="small"
              active={true}
              label="Cancel"
              color="--color-black"
              onClick={onClose}
              allert={
                <h1
                  style={{
                    padding: "80px",
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to leave the editing window?
                </h1>
              }
            />

            <Button
              type="small"
              active={isModified}
              label="Save"
              onClick={handleUpdateFields}
              allert={
                <h1
                  style={{
                    padding: "80px",
                    textAlign: "center",
                  }}
                >
                  Please confirm that you want to update the user information.
                </h1>
              }
            />
          </div>
        </ButtonsBar>
      </ContentBox>
    </div>
  );
};

export default WindowUserProfileModeration;
