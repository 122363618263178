import React from "react";
import "./avatars.css";
import Preloader from "../../../../../UIElements/Preloader";

const AvatarUser = ({ size, url, isLoading = false }) => {
  console.log("AvatarUser url", url);
  const defaultUrl =
    "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fprofile%2FavatarDefault.svg?alt=media&token=18a44cb0-a166-4f6e-ac52-d5567d616811";
  const backgroundImageUrl = url ? `url(${url})` : `url(${defaultUrl})`;

  return isLoading ? (
    <div
      className="avatar_user"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "var(--color-gray-200)",
        width: `${size}px`,
        // height: `${size}px`,
        aspectRatio: "1/1",
      }}
    >
      <Preloader />
    </div>
  ) : (
    <div
      className="avatar_user"
      style={{
        backgroundImage: backgroundImageUrl,
        width: `${size}px`,
        // height: `${size}px`,
        aspectRatio: "1/1",
      }}
    />
  );
};

export default AvatarUser;
