import { createSlice } from "@reduxjs/toolkit";
import { createDataThunkV4 } from "./thunk/catalog/V4/CRUD/createDataThunkV4";
import { updateFilePriorityThunkV4 } from "./thunk/catalog/V4/updateFilePriorityThunkV4";
import { deleteFilesThunkV4 } from "./thunk/catalog/V4/deleteFilesThunkV4";
import { fetchPreviewDataV4 } from "./thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import { fetchRootDataThunkV4 } from "./thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { updateDataThunkV4 } from "./thunk/catalog/V4/CRUD/updateDataThunkV4";
import { deleteDataThunkV4 } from "./thunk/catalog/V4/CRUD/deleteDataThunkV4";
import { emailSignInThunkV4 } from "./thunk/user/emailSignInThunkV4";
import { emailSignUpThunkV4 } from "./thunk/user/emailSignUpThunkV4";
import { logoutThunkV4 } from "./thunk/user/logoutThunkV4";
import { googleSignUpThunkV4 } from "./thunk/user/googleSignUpThunkV4";

// Утилита для получения состояния по пути
const getStateByPath = (state, path) => {
  return path.split(".").reduce((acc, key) => acc && acc[key], state);
};

// Утилита для установки значения в состоянии по пути
// const setStateByPath = (state, path, value) => {
//   const keys = path.split(".");

//   let current = state;

//   for (let i = 0; i < keys.length - 1; i++) {
//     if (!current[keys[i]]) {
//       current[keys[i]] = {}; // Создаем вложенные объекты, если они отсутствуют
//     }
//     current = current[keys[i]];
//   }
//   current[keys[keys.length - 1]] = value;
// };
const setStateByPath = (state, path, value) => {
  const keys = path.split(".");
  let current = state;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];

    // Проверяем, является ли ключ индексом массива
    const isArrayIndex = !isNaN(parseInt(key));

    if (isArrayIndex) {
      const index = parseInt(key);
      if (!Array.isArray(current)) {
        // Если current не массив, модифицируем текущий уровень состояния, а не локальную переменную
        current = [];
      }
      if (!current[index]) {
        current[index] = {}; // Создаем элемент массива, если он отсутствует
      }
      current = current[index];
    } else {
      // Работаем с объектами
      if (!current[key]) {
        current[key] = {}; // Создаем вложенные объекты, если они отсутствуют
      }
      current = current[key];
    }
  }

  const lastKey = keys[keys.length - 1];
  const isArrayIndex = !isNaN(parseInt(lastKey));

  if (isArrayIndex) {
    const index = parseInt(lastKey);
    if (!Array.isArray(current)) {
      current[keys[keys.length - 2]] = []; // Присваиваем массив предыдущему объекту
      current = current[keys[keys.length - 2]]; // Присваиваем массив в текущий уровень
    }
    current[index] = value; // Обновляем элемент массива по индексу
  } else {
    current[lastKey] = value; // Обновляем значение объекта
  }
};

/** User */
const rootUserData = {
  status: "moderation",
  role: "client",

  createdat: Date.now(),

  company: {
    companyName: "",
    mailingAddress: "",
    timeZone: "",
    description: {
      descriptionOriginal: "",
    },
  },

  metaData: {
    offerConfirm: "",
    language: "en",
  },

  files: {
    businessLicenseFiles: [],
    localPermitsFiles: [],
    articlesOfAssociationFiles: [],
  },

  user: {
    contactingTheUser: "",
    gender: "",
    yourGender: "",
    ownerName: "",
    ownerSurname: "",
  },

  userContacts: {
    email: "",
    phoneNumber: "",
  },

  rentalPointsIds: [],
  id: "",
};

const previewUserData = {
  createdat: Date.now(),

  avatarUrl: "",
  ownerName: "",
  email: "",
  phoneNumber: "",

  status: "",
  role: "",
};
/** RentalPoint */
const rootRentalPointData = {
  bikesIds: [],
  ordersIds: [],
  created: 0,

  dirrectory: {
    cityId: "",
    countryId: "",
  },

  rentalPointStatus: {
    currentStatus: "moderation",
  },

  basicInformation: {
    title: {
      titleOriginal: "",
    },

    description: {
      descriptionOriginal: "",
    },
  },

  location: {
    pointAddress: "",
    location: {
      lat: null,
      lng: null,
    },
    city: {},
    country: {},
  },

  files: {
    licenseFile: [],
    photoFiles: [],
    logoFile: [],
  },

  contacts: {
    emailAddresses: [],
    phoneNumbers: [],
  },

  additionalServices: [],

  timetable: [
    {
      weekday: {
        weekdayOriginal: "Monday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Tuesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekday_En: "Wednesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekday_En: "Thursday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekday_En: "Friday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekday_En: "Saturday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
    },
    {
      weekday: {
        weekday_En: "Sunday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
    },
  ],
};
const previewRentalPointData = {
  created: 0,

  rentalPointStatus: "moderation",
  title: "",
  description: "",

  files: {
    licenseFile: "",
    photoFiles: "",
    logoFile: "",
  },
};

const catalogSliceV4 = createSlice({
  name: "catalogSliceV4",
  initialState: {
    previewNewData: {
      previewUserData: previewUserData,
      previewRentalPointData: previewRentalPointData,
    },

    rootNewData: {
      rootUserData: rootUserData,
      rootRentalPointData: rootRentalPointData,
    },

    tempPreviewData: {},
    tempRootData: {},
    tempData: {},
    // Динамические состояние загрузки и ошибок

    loading: {
      loading_signIn: false,
      loading_signUp: false,
      loading_logOut: false,
    },
    error: {
      error_signIn: null,
      error_signUp: null,
      error_logOut: null,
    },

    // Системый state который используется для хранения структуры firestore
    users: {},
    countries: {},
  },

  reducers: {
    // setCurrentPreviewData: (state, action) => {
    //   const { currentPreviewDocumenState, data } = action.payload;
    //   state.tempPreviewData[currentPreviewDocumenState] = data;
    // },

    // setCurrentRootData: (state, action) => {
    //   const { rootCurrentDocumentState, data } = action.payload;
    //   state.tempRootData[rootCurrentDocumentState] = data;
    // },

    setCurrentPreviewData: (state, action) => {
      const { currentPreviewDocumenState, data } = action.payload;
      setStateByPath(state.tempPreviewData, currentPreviewDocumenState, data);
    },

    setCurrentRootData: (state, action) => {
      const { rootCurrentDocumentState, data } = action.payload;
      setStateByPath(state.tempRootData, rootCurrentDocumentState, data);
    },

    setTempData: (state, action) => {
      const { tempCurrentDocumentState, data } = action.payload;
      setStateByPath(state.tempData, tempCurrentDocumentState, data);
    },

    /** subscribePreviewDocumentsThunkV4 */
    updateDocuments: (state, action) => {
      const { documents, previewCurrentDocumenState } = action.payload;
      state.tempPreviewData[previewCurrentDocumenState] = documents;
    },

    setLoadingState: (state, action) => {
      const { loadingStateName, isLoading } = action.payload;
      state.loading[loadingStateName] = isLoading;
    },

    setErrorState: (state, action) => {
      const { errorStateName, errorMessage } = action.payload;
      state.error[errorStateName] = errorMessage;
    },
    /** subscribePreviewDocumentsThunkV4 */
    setResetErrorLogin: (state, action) => {
      state.error = {
        error_signIn: null,
        error_signUp: null,
        error_logOut: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder

      /* *** Добавляем обработку fetchPreviewDataV4 *** */

      .addCase(fetchPreviewDataV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(fetchPreviewDataV4.fulfilled, (state, action) => {
        const {
          previewCollectionPath,
          previewDocumentsData,
          lastUpdated,
          previewCurrentDocumenState,
        } = action.payload;
        const { loadingStateName, errorStateName } = action.meta.arg;

        let previewState = getStateByPath(state, previewCollectionPath);

        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }

        previewState.data = previewDocumentsData;
        previewState.lastUpdated = lastUpdated;

        // Обновляем текущие элементы
        if (previewCurrentDocumenState) {
          state.tempPreviewData[previewCurrentDocumenState] =
            previewDocumentsData || [];
        }

        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(fetchPreviewDataV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку fetchRootDataThunkV4 *** */

      .addCase(fetchRootDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;

        // Сбрасываем состояние загрузки
        state.loading[loadingStateName] = true;

        // Устанавливаем ошибку
        state.error[errorStateName] = null;
      })
      .addCase(fetchRootDataThunkV4.fulfilled, (state, action) => {
        const {
          rootCollectionPath,
          rootDocumentData,
          lastUpdated,
          rootCurrentDocumentState,
        } = action.payload;
        const { loadingStateName, errorStateName } = action.meta.arg;

        console.log("Получен rootCollectionPath:", rootCollectionPath);
        console.log("Получен rootDocumentData:", rootDocumentData);

        // Разбиваем rootCollectionPath на ключи
        const rootKeys = rootCollectionPath.split(".");

        // Навигируем или создаем вложенную структуру в состоянии для rootCollectionPath
        let rootState = state;

        for (let i = 0; i < rootKeys.length; i++) {
          const key = rootKeys[i];

          if (i === rootKeys.length - 1) {
            // Если последний ключ, инициализируем как объект, если необходимо
            if (!rootState[key] || Array.isArray(rootState[key])) {
              rootState[key] = {}; // Инициализация объекта
            }
          } else {
            // Промежуточные ключи, инициализируем как объекты, если необходимо
            if (!rootState[key] || Array.isArray(rootState[key])) {
              rootState[key] = {}; // Инициализация объекта
            }
          }

          rootState = rootState[key];
        }

        console.log("rootState перед проверкой наличия документа:", rootState);

        // Проверяем наличие объекта по idRootDocument и его актуальность
        const existingDocument = rootState[rootDocumentData.idRootDocument];

        if (existingDocument && existingDocument.lastUpdated >= lastUpdated) {
          // Объект уже существует и актуален, ничего не делаем
          console.log(
            `Документ с idRootDocument ${rootDocumentData.idRootDocument} уже существует и актуален.`
          );
        } else {
          // Добавляем или обновляем объект по идентификатору
          rootState[rootDocumentData.idRootDocument] = {
            ...rootDocumentData,
            lastUpdated,
          };

          console.log(
            "rootState после добавления rootDocumentData:",
            rootState
          );
        }

        // Обновляем текущие элементы
        if (rootCurrentDocumentState) {
          state.tempRootData[rootCurrentDocumentState] = {
            ...rootDocumentData,
            lastUpdated,
          };
        }

        // Сбрасываем состояние загрузки
        state.loading[loadingStateName] = false;

        // Устанавливаем ошибку
        state.error[errorStateName] = null;
      })
      .addCase(fetchRootDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;

        // Сбрасываем состояние загрузки
        state.loading[loadingStateName] = false;
        // Устанавливаем ошибку
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку createDataThunkV4 *** */

      .addCase(createDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(createDataThunkV4.fulfilled, (state, action) => {
        const {
          rootCollectionPath,
          previewCollectionPath,
          rootDocumentData,
          previewDocumentData,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Обновляем rootState
        let rootState = getStateByPath(state, rootCollectionPath);
        if (!rootState) {
          setStateByPath(state, rootCollectionPath, {});
          rootState = getStateByPath(state, rootCollectionPath);
        }
        rootState[rootDocumentData.idRootDocument] = rootDocumentData;
        console.log(
          ".addCase(createDataThunkV4.fulfilled",
          rootState[rootDocumentData.idRootDocument],

          JSON.parse(JSON.stringify(rootState))
        );
        // Обновляем previewState
        let previewState = getStateByPath(state, previewCollectionPath);

        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }

        previewState.data.push(previewDocumentData);
        previewState.lastUpdated = Date.now();

        console.log(
          ".addCase(createDataThunkV4.fulfilled previewState",

          JSON.parse(JSON.stringify(previewState))
        );

        // Обновляем текущие элементы
        if (rootCurrentDocumentState) {
          state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        }

        if (previewCurrentDocumenState) {
          state.tempPreviewData[previewCurrentDocumenState] = previewState.data;
        }

        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;

        console.log(
          "state.tempRootData",
          JSON.parse(JSON.stringify(state.tempRootData))
        );
      })
      .addCase(createDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку updateDataThunkV4 *** */

      .addCase(updateDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(updateDataThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          previewDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Обновляем rootState
        let rootState = getStateByPath(state, rootCollectionPath);
        if (!rootState) {
          setStateByPath(state, rootCollectionPath, {});
          rootState = getStateByPath(state, rootCollectionPath);
        }
        rootState[rootDocumentData.idRootDocument] = rootDocumentData;

        // Обновляем previewState
        let previewState = getStateByPath(state, previewCollectionPath);
        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }

        const previewIndex = previewState.data.findIndex(
          (item) => item.idRootDocument === previewDocumentData.idRootDocument
        );

        if (previewIndex !== -1) {
          previewState.data[previewIndex] = previewDocumentData;
        } else {
          previewState.data.push(previewDocumentData);
        }

        previewState.lastUpdated = Date.now();

        // Обновляем текущие элементы
        if (rootCurrentDocumentState) {
          state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        }

        if (previewCurrentDocumenState) {
          state.tempPreviewData[previewCurrentDocumenState] = previewState.data;
        }

        // Сбрасываем состояние загрузки и ошибки
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(updateDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку deleteDataThunkV4 *** */

      .addCase(deleteDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(deleteDataThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          previewDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Обновляем rootState
        let rootState = getStateByPath(state, rootCollectionPath);
        if (!rootState) {
          setStateByPath(state, rootCollectionPath, {});
          rootState = getStateByPath(state, rootCollectionPath);
        }

        // Обновляем или добавляем документ в rootState
        if (rootState[rootDocumentData.idRootDocument]) {
          rootState[rootDocumentData.idRootDocument].remove =
            rootDocumentData.remove;
        } else {
          rootState[rootDocumentData.idRootDocument] = rootDocumentData;
        }

        // Обновляем previewState
        let previewState = getStateByPath(state, previewCollectionPath);
        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }

        const previewIndex = previewState.data.findIndex(
          (item) => item.idRootDocument === previewDocumentData.idRootDocument
        );

        if (previewIndex !== -1) {
          previewState.data[previewIndex].remove = previewDocumentData.remove;
        } else {
          previewState.data.push(previewDocumentData);
        }

        previewState.lastUpdated = Date.now();

        // Обновляем текущие элементы
        if (rootCurrentDocumentState) {
          state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        }

        if (previewCurrentDocumenState) {
          state.tempPreviewData[previewCurrentDocumenState] = previewState.data;
        }

        // Сбрасываем состояние загрузки и ошибки
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(deleteDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку deleteFilesThunkV4 *** */

      .addCase(deleteFilesThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(deleteFilesThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          previewDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Обновляем rootState
        let rootState = getStateByPath(state, rootCollectionPath);
        if (!rootState) {
          setStateByPath(state, rootCollectionPath, {});
          rootState = getStateByPath(state, rootCollectionPath);
        }
        rootState[rootDocumentData.idRootDocument] = rootDocumentData;

        // Обновляем previewState
        let previewState = getStateByPath(state, previewCollectionPath);
        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }

        const index = previewState.data.findIndex(
          (item) => item.idRootDocument === previewDocumentData.idRootDocument
        );
        if (index !== -1) {
          previewState.data[index] = previewDocumentData;
        } else {
          previewState.data.push(previewDocumentData);
        }
        previewState.lastUpdated = Date.now();

        // Обновляем текущие элементы
        if (rootCurrentDocumentState) {
          state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        }

        if (previewCurrentDocumenState) {
          state.tempPreviewData[previewCurrentDocumenState] = previewState.data;
        }

        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(deleteFilesThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку updateFilePriorityThunkV4 *** */

      .addCase(updateFilePriorityThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(updateFilePriorityThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          previewDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Обновляем rootState
        let rootState = getStateByPath(state, rootCollectionPath);
        if (!rootState) {
          setStateByPath(state, rootCollectionPath, {});
          rootState = getStateByPath(state, rootCollectionPath);
        }
        rootState[rootDocumentData.idRootDocument] = rootDocumentData;

        // Обновляем previewState
        let previewState = getStateByPath(state, previewCollectionPath);
        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }

        const index = previewState.data.findIndex(
          (item) => item.idRootDocument === previewDocumentData.idRootDocument
        );
        if (index !== -1) {
          previewState.data[index] = previewDocumentData;
        } else {
          previewState.data.push(previewDocumentData);
        }
        previewState.lastUpdated = Date.now();

        // Обновляем текущие элементы
        state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        state.tempPreviewData[previewCurrentDocumenState] = previewState.data;

        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(updateFilePriorityThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку emailSignInThunkV4 *** */

      .addCase(emailSignInThunkV4.pending, (state) => {
        state.loading.loading_signIn = true;
        state.error.error_signIn = null;
      })
      .addCase(emailSignInThunkV4.fulfilled, (state, action) => {
        state.loading.loading_signIn = false;
        state.error.error_signIn = null;
      })
      .addCase(emailSignInThunkV4.rejected, (state, action) => {
        state.loading.loading_signIn = false;
        state.error.error_signIn = action.payload.errorMessage;
      })

      /* *** Добавляем обработку emailSignUpThunkV4 *** */

      .addCase(emailSignUpThunkV4.pending, (state) => {
        state.loading.loading_signUp = true;
        state.error.error_signUp = null;
      })
      .addCase(emailSignUpThunkV4.fulfilled, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = null;
      })
      .addCase(emailSignUpThunkV4.rejected, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = action.payload.errorMessage;
      })

      /* *** Добавляем обработку googleSignUpThunkV4 *** */

      .addCase(googleSignUpThunkV4.pending, (state) => {
        state.loading.loading_signUp = true;
        state.error.error_signUp = null;
      })
      .addCase(googleSignUpThunkV4.fulfilled, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = null;
      })
      .addCase(googleSignUpThunkV4.rejected, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = action.payload.errorMessage;
      })

      /* *** Добавляем обработку logoutThunkV4 *** */

      .addCase(logoutThunkV4.pending, (state) => {
        state.loading.loading_logOut = true;
        state.error.error_logOut = null;
      })
      .addCase(logoutThunkV4.fulfilled, (state, action) => {
        state.loading.loading_logOut = false;
        state.error.error_logOut = null;
      })
      .addCase(logoutThunkV4.rejected, (state, action) => {
        state.loading.loading_logOut = false;
        state.error.error_logOut = action.payload.errorMessage;
      });
  },
});

export const {
  setCurrentPreviewData,
  setCurrentRootData,
  setTempData,
  setPreviewCurrentCountry,
  setRootCurrentCountry,
  setRootCurrentCity,
  setPreviewCurrentCity,
  updateDocuments,
  setLoadingState,
  setErrorState,
  setResetErrorLogin,
} = catalogSliceV4.actions;
export const { reducer: catalogReducer } = catalogSliceV4;
export default catalogSliceV4.reducer;
