import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { v4 as uuidv4 } from "uuid";
import { createDataThunkV4 } from "../catalog/V4/CRUD/createDataThunkV4";

export const emailSignUpThunkV4 = createAsyncThunk(
  "reducersToolkit/emailSignUpThunkV4",
  async (
    { email, password, userName, createdat },
    { dispatch, rejectWithValue, getState }
  ) => {
    const auth = getAuth();
    const state = getState();
    const catalogState = state.catalogSliceV4;

    // Достаем сохраненные данные из catalogSliceV4
    const rootUserState = catalogState?.rootNewData?.rootUserData || null;
    const previewUserState =
      catalogState?.previewNewData?.previewUserData || null;

    console.log("rootUserState:", rootUserState);
    console.log("previewUserState:", previewUserState);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (!userCredential.user) {
        console.error("User is not authenticated.");
        throw new Error("User is not authenticated.");
      }

      const rootUserData = {
        ...rootUserState,
        createdat: Date.now(),

        status: "moderation",
        role: "client",

        user: {
          ownerName: userName || "",
        },

        userContacts: {
          email: email,
          phoneNumber: userCredential.user.phoneNumber || "",
        },

        id: userCredential.user.uid,
      };

      const previewUserData = {
        ...previewUserState,

        createdat: Date.now(),
        fileUrl: userCredential.user.photoURL || "",
        ownerName: userName || "",
        email: email,
        phoneNumber: userCredential.user.phoneNumber || "",

        status: "moderation",
        role: "client",
      };

      await dispatch(
        createDataThunkV4({
          // Данные которые будут использоваться для создания коллекции в firestore в разделе root
          // data: rootCountryData,
          rootData: rootUserData,
          // id документа. Если id отсутствует, то он формируется автоматически в firestore
          idRootDocument: userCredential.user.uid,
          //  // Данные которые будут использоваться для создания коллекции в firestore в разделе preview
          previewData: previewUserData,
          // files: {
          //   // или другие массивы файлов, если есть
          //   iconFiles: [userCredential.user.photoURL || ""],
          // },
          // Определяем пути для коллекций и метаданных с точками в качестве разделителей
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          previewCollectionPath: "usersPreview",
          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: "usersPreview.metadata",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "user_auth_previewData",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "emailSignUpThunkV2LoadingCreateUser",
          errorSatetName: "emailSignUpThunkV2ErrorCreateUser",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("Пользователь успешно создан:", response);
        })
        .catch((error) => {
          console.error("Ошибка при создании пользователя:", error);
        });

      return {
        currentUser: rootUserData,
        rootData: rootUserData,
        previewData: previewUserData,
      };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      console.error(
        "Failed to load user data. Please try again later or contact support. Error: ",
        errorMessage
      );
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// import { addDocumentById } from "../../../services/firebase/crudFirebaseStorage";
// import { Timestamp } from "firebase/firestore";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// export const emailSignUpThunkV2 = createAsyncThunk(
//   "reducersToolkit/emailSignUpThunkV2",
//   async ({ email, password, userName, createdat }, { rejectWithValue }) => {
//     const auth = getAuth();

//     try {
//       // Регистрируем пользователя с помощью Firebase Auth
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );

//       // Проверяем, аутентифицирован ли пользователь
//       if (!userCredential.user) {
//         console.error("User is not authenticated.");
//         throw new Error("User is not authenticated.");
//       }

//       // Получаем текущую дату и время в формате Timestamp Firestore
//       const timestamp = Timestamp.fromDate(new Date());
//       const timestampSeconds = Math.floor(Date.now() / 1000);

//       const userData = {
//         createdat: createdat,
//         email: email,
//         phoneNumber: userCredential.user.phoneNumber || "",
//         avatarUrl: userCredential.user.photoURL || "",
//         contactingTheUser: userCredential.user.displayName || "",
//       };

//       // Подготовка данных пользователя для Firestore
//       const currentUser = {
//         user: {
//           contactingTheUser: userCredential.user.displayName || "",
//           ownerName: "",
//           ownerSurname: "",
//           gender: "",
//           yourGender: "",
//           avatar: [
//             {
//               url: userCredential.user.photoURL || "",
//               prioriry: false,
//             },
//           ],
//         },
//         userContacts: {
//           email: email,
//           phoneNumber: userCredential.user.phoneNumber || "",
//         },
//         company: {
//           companyName: "",
//           mailingAddress: "",
//           timeZone: "",
//         },

//         id: userCredential.user.uid,
//         stateProfile: "new",
//         // name: "",
//         // displayName: displayName || "",
//         // photoURL: photoURL || "",
//         role: "rental",
//         providerData: userCredential.user.providerData || [],
//         offerConfirm: timestampSeconds,
//       };

//       // Добавление данных пользователя в Firestore и получение ID добавленного документа
//       await addDocumentById(currentUser, "users", userCredential.user.uid);

//       // Возвращаем данные пользователя вместе с ID добавленного документа
//       return { currentUser: currentUser.user };
//     } catch (error) {
//       const errorMessage = interpretAuthErrorV2(error.code);
//       console.error(
//         "Failed to load user data. Please try again later or contact support. Error: ",
//         errorMessage
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
