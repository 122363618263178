import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import InputText from "../../WidgetsProfile/Inputs/InputText";
import Textarea from "../../WidgetsProfile/Textarea/Textarea";
import AddData from "../../WidgetsProfile/AddData/AddData";
import WidgetListFile from "../../WidgetsProfile/UploadFiles/WidgetListFile";
import Button from "../../WidgetsProfile/Buttons/Button";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import WindowUploadFiles from "../Directory/WindowUploadFiles";
import { addDirectoryCityThunkV2 } from "../../../../../../reducersToolkit/thunk/directories/addDirectoryCityThunkV2";
import { updateDirectoryCityThunkV2 } from "../../../../../../reducersToolkit/thunk/directories/updateDirectoryThunkV2";
import { deleteCityThunkV2 } from "../../../../../../reducersToolkit/thunk/directories/deleteCityThunkV2";
import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";
import WidgetAlert from "../../WidgetsProfile/Alerts/WidgetAlert";
import { createCityThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/createCityThunkV4";
import { v4 as uuidv4 } from "uuid";
import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
import { updateCityThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/updateCityThunkV4";
import { deleteCityThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/deleteCityThunkV4";
import { createDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/createDataThunkV4";
import { updateDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import { fetchRootDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { setCurrentRootData } from "../../../../../../reducersToolkit/catalogSliceV4";
import { deleteDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/deleteDataThunkV4";

/*
В WidgetCitiesListV4.js в preview стейт под именем selectedPreviewCity записываем выбранный город

В WidgetCountriesListV4.js в preview стейт под именем selectedPreviewCountry записываем выбранную страну
*/

const WindowCityDetailV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const { tempPreviewData, tempRootData, loading } = useSelector(
    (state) => state.catalogSliceV4
  );

  // Отображение текущих данных в форме
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // Данные для проверки заполненности полей, чтобы включать кнопку SAVE
  const [originalTitle, setOriginalTitle] = useState("");
  const [originalDescription, setOriginalDescription] = useState("");
  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);

  // Новые файлы
  const [newPhotoFiles, setNewPhotoFiles] = useState([]);
  // Стейты из CRUD thunks

  const [rootCurrentCity, setRootCurrentCity] = useState(null);
  // const [curentCountryId, setCurentCountryId] = useState(null);
  const [previewCityId, setPreviewCityId] = useState(null);
  const [rootCityId, setRootCityId] = useState(null);
  const [rootCountryId, setRootCountryId] = useState(null);
  const [previewCountryId, setPreviewCountryId] = useState(null);

  // LOADING states
  const [
    windowCityDetailV4LoadingFetchCity,
    setWindowCityDetailV4LoadingFetchCity,
  ] = useState(false);
  const [
    windowCityDetailV4LoadingCreateCity,
    setWindowCityDetailV4LoadingCreateCity,
  ] = useState(false);
  const [
    windowCityDetailV4LoadingUpdateCity,
    setWindowCityDetailV4LoadingUpdateCity,
  ] = useState(false);
  const [
    windowCityDetailV4LoadingDeleteCity,
    setWindowCityDetailV4LoadingDeleteCity,
  ] = useState(false);

  const [
    windowCityDetailV4LoadingIconFiles,
    setWindowCityDetailV4LoadingIconFiles,
  ] = useState(false);

  useEffect(() => {
    // Отображение текущих данных в форме
    setTitle(tempRootData?.rootCurrentCity?.title?.titleOriginal || "");
    setDescription(
      tempRootData?.rootCurrentCity?.description?.descriptionOriginal || ""
    );

    // Данные для проверки заполненности полей, чтобы включать кнопку SAVE
    setOriginalTitle(tempRootData?.rootCurrentCity?.title?.titleOriginal || "");
    setOriginalDescription(
      tempRootData?.rootCurrentCity?.description?.descriptionOriginal || ""
    );
    setPreviewPhotoFiles(tempRootData?.rootCurrentCity?.files?.iconFiles || []);
  }, [tempRootData]);

  // Стейты из CRUD thunks
  useEffect(() => {
    setPreviewCountryId(
      tempPreviewData?.selectedPreviewCountry?.idPreviewDocument || null
    );
    setRootCountryId(
      tempPreviewData?.selectedPreviewCountry?.idRootDocument || null
    );
    setRootCurrentCity(tempRootData?.rootCurrentCity || {});
    setPreviewCityId(
      tempPreviewData?.selectedPreviewCity?.idPreviewDocument || null
    );
    setRootCityId(tempPreviewData?.selectedPreviewCity?.idRootDocument || null);
  }, [
    tempPreviewData?.selectedPreviewCountry,
    tempPreviewData?.previewCountryData,
    tempRootData?.rootCurrentCity,
    tempPreviewData?.selectedPreviewCity,
  ]);

  // LOADING states
  useEffect(() => {
    setWindowCityDetailV4LoadingIconFiles(
      loading.windowCityDetailV4LoadingIconFiles || false
    );
    // Получение
    setWindowCityDetailV4LoadingFetchCity(
      loading.windowCityDetailV4LoadingFetchCity || false
    );
    // Создание
    setWindowCityDetailV4LoadingCreateCity(
      loading.windowCityDetailV4LoadingCreateCity || false
    );
    // Обновление
    setWindowCityDetailV4LoadingUpdateCity(
      loading.windowCityDetailV4LoadingUpdateCity || false
    );
    // Удаление
    setWindowCityDetailV4LoadingDeleteCity(
      loading.windowCityDetailV4LoadingDeleteCity || false
    );
  }, [
    loading.windowCityDetailV4LoadingUpdateCity,
    loading.windowCityDetailV4LoadingFetchCity,
    loading.windowCityDetailV4LoadingCreateCity,
    loading.windowCityDetailV4LoadingDeleteCity,
    loading.windowCityDetailV4LoadingIconFiles,
  ]);

  /*
  CITIES 
  */

  useEffect(() => {
    if (mode === "edit") {
      // Если есть rootCityId страны, выполняем запрос для получения данных для детализации
      if (rootCityId) {
        console.log(
          `Есть rootCityId: ${rootCityId} страны, выполняем запрос для получения данных для детализации countries.${rootCountryId}.cities`
        );
        dispatch(
          fetchRootDataThunkV4({
            // Id документа в root коллекции
            idRootDocument: rootCityId,
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: `countries.${rootCountryId}.cities`,
            // Название состояния в которое будет сохранен объект после выполнения thunk
            rootCurrentDocumentState: "rootCurrentCity",
            // Эти состояния будут отображать состояния загрузки и ошибки
            loadingStateName: "windowCityDetailV4LoadingFetchCity",
            errorSatetName: "fetchRootDataThunkV4ErrorRootCity",
          })
        );
      }
    } else if (mode === "new") {
      // Иначе просто обнуляем объект страны
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "rootCurrentCity",
          data: {},
        })
      );
    }
  }, [dispatch, rootCountryId, mode, rootCityId]);

  const handleCreateOrUpdateData = async () => {
    try {
      if (mode === "new") {
        dispatch(
          createDataThunkV4({
            // Данные для создания, как правило это объект схожий по структуре с документом в firestore
            rootData: cityData,
            previewData: cityPreviewData,
            // Массивы файлов
            files: {
              /* Другие массивы файлов. Массивов файлов может быть несколько*/
              iconFiles: newPhotoFiles,
            },
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: `countries.${rootCountryId}.cities`,
            previewCollectionPath: `citiesPreview.countryIds.${rootCountryId}`,
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath: `citiesPreview.countryIds.${rootCountryId}.metadata`,
            // Название state который будет содержать результат работы thunk
            rootCurrentDocumentState: "rootCurrentCity",
            previewCurrentDocumenState: "previewCitiesList",
            // Название state который будет помогать отслежывать результаты работы thunk
            loadingStateName: "windowCityDetailV4LoadingCreateCity",
            errorSatetName: "windowCityDetailV4ErrorCreateCity",
          })
        )
          .unwrap()
          .then((response) => {
            console.log("Город успешно создан:", response);
            onClose();
          })
          .catch((error) => {
            console.error("Ошибка при создании города:", error);
          });
        onClose();
      } else if (mode === "edit") {
        if (previewCityId && rootCityId) {
          dispatch(
            updateDataThunkV4({
              // Указываем id preview документа который будем менять
              idPreviewDocument: previewCityId,
              // Указываем id root Элемента который будем менять
              idRootDocument: rootCityId,
              // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
              updatedData: cityData,
              // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
              newFiles: {
                iconFiles: newPhotoFiles,
                /* Другие массивы файлов. Массивов файлов может быть несколько*/
              },
              // Путь до коллекции указывается из НЕ четного колличества вложенностей
              rootCollectionPath: `countries.${rootCountryId}.cities`,
              previewCollectionPath: `citiesPreview.countryIds.${rootCountryId}`,
              // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
              metadataDocumentPath: `citiesPreview.countryIds.${rootCountryId}.metadata`,
              // Сохраняет с state МАССИВ объектов как результат выполнения thunk
              rootCurrentDocumentState: "rootCurrentCity",
              // Сохраняет с state ОБЪЕКТ как результат выполнения thunk
              previewCurrentDocumenState: "previewCitiesList",
              // Эти состояния будут отображать состояния загрузки и ошибки
              loadingStateName: "windowCityDetailV4LoadingUpdateCity",
              errorSatetName: "windowCityDetailV4ErrorUpdateCity",
            })
          );
          onClose();
        }
      }
    } catch (error) {
      console.error("Ошибка при создании или обновлении страны:", error);
      onClose();
    }
  };

  const handleDeleDirectory = () => {
    dispatch(
      deleteDataThunkV4({
        idPreviewDocument: previewCountryId,
        idRootDocument: rootCountryId,
        // Определение путей коллекций
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: "countries",
        previewCollectionPath: "countriesPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: "countriesPreview.metadata",
        // Название state который будет содержать результат работы thunk
        previewCurrentDocumenState: "previewCitiesList",
        // Название state который будет помогать отслежывать результаты работы thunk
        loadingStateName: "windowCityDetailV4LoadingDeleteCity",
        errorSatetName: "windowCityDetailV4ErrorCity",
      })
    );
    onClose();
  };

  /*
  CITIES END
  */

  const handleSetName = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  const cityData = {
    ...rootCurrentCity,
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const cityPreviewData = {
    title: title || "",
    description: (description || "").slice(0, 250),
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const isButtonActive =
    newPhotoFiles.length > 0 ||
    title !== originalTitle ||
    description !== originalDescription;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox
          position="start"
          isLoading={
            windowCityDetailV4LoadingUpdateCity ||
            windowCityDetailV4LoadingFetchCity ||
            windowCityDetailV4LoadingCreateCity ||
            windowCityDetailV4LoadingDeleteCity
          }
        >
          <WidgetListFileV4
            isLoading={windowCityDetailV4LoadingIconFiles}
            // Передаём данные для отображения
            viewFiles={previewPhotoFiles}
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath={`countries.${rootCountryId}.cities`}
            previewCollectionPath={`citiesPreview.countryIds.${rootCountryId}`}
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath={`citiesPreview.countryIds.${rootCountryId}.metadata`}
            // Редактируемые документы
            idPreviewDocument={previewCityId}
            idRootDocument={rootCityId}
            // Поле в котором находится массив с файлами
            fieldName="files.iconFiles"
            // Динамические состояния загрузки
            loadingStateName="windowCityDetailV4LoadingIconFiles"
            errorSatetName="errorIconFiles"
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState="rootCurrentCity"
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            previewCurrentDocumenState="previewCitiesList"
            // Режим
            mode="edit"
          />

          <WidgetUploadFilesV4
            isLoading={windowCityDetailV4LoadingIconFiles}
            id="setPhotoCityFiles"
            title="Add photo city"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setNewPhotoFiles}
          />

          <InputText
            label="Name"
            description="Enter the name of the country."
            value={title}
            onChange={handleSetName}
            type="text"
          />
          <Textarea
            label="Description"
            maxLength={5000}
            value={description}
            onChange={handleSetDescription}
          />
        </ContentBox>

        {mode === "edit" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />
              <Button
                type="small"
                label="Delete"
                active={true}
                onClick={() => handleDeleDirectory()}
                color="--color-alarm"
                allert={
                  <WidgetAlert
                    title={` Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />
              <Button
                type="small"
                label="Save"
                active={isButtonActive}
                onClick={() => handleCreateOrUpdateData()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to save the changes?`}
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}
        {mode === "new" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />

              <Button
                type="small"
                label="Save"
                active={title !== "" && description !== ""}
                onClick={() => handleCreateOrUpdateData()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to save the changes?`}
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}
      </div>
    </>
  );
};

export default WindowCityDetailV4;
