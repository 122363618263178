import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
import { db } from "../../../../../services/firebase/authFirebase";
import { getIconFileUrl } from "../fetchCatalogData/utils";
import { v4 as uuidv4 } from "uuid";
import interpretAuthErrorV2 from "../../../../../functions/interpretAuthErrorV2";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// Максимальное количество попыток
const MAX_RETRIES = 3;

// Список кодов ошибок, для которых будут выполняться повторные попытки
const RETRYABLE_ERRORS = [
  "unavailable", // Firestore backend не доступен
  "deadline-exceeded", // Превышено время ожидания запроса
  "internal", // Внутренняя ошибка Firestore
  "failed-precondition", // Некорректные пред условия
  "network-error", // Ошибка сети
];

export const updateDataThunkV4 = createAsyncThunk(
  "countries/updateDataThunkV4",
  async (
    {
      rootData,
      previewData,
      newFiles = {},
      idPreviewDocument = null,
      idRootDocument,
      rootCollectionPath,
      previewCollectionPath,
      metadataDocumentPath,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      loadingStateName,
      errorSatetName,
    },
    { rejectWithValue }
  ) => {
    let attempt = 0; // Счетчик попыток

    while (attempt < MAX_RETRIES) {
      try {
        // 1. Получение ссылки на документ корневой коллекции
        const docRef = doc(
          db,
          ...rootCollectionPath.split("."),
          idRootDocument
        );

        // 2. Проверка наличия документа перед обновлением
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          return rejectWithValue(
            `Document with ID ${idRootDocument} does not exist.`
          );
        }

        // 3. Получение текущих файлов из Firestore
        const currentFiles = docSnap.data().files || {};

        // 4. Загрузка новых файлов в Firebase Storage и получение URL
        const uploadedFiles = {};
        if (newFiles && Object.keys(newFiles).length > 0) {
          for (const [fieldName, fileArray] of Object.entries(newFiles)) {
            if (fileArray && fileArray.length > 0) {
              const uploaded = await uploadFilesToFirebase(
                `files/${idRootDocument}/${fieldName}`,
                fileArray
              );
              const formattedFiles = uploaded.map((file) => ({
                url: file.url,
                name: file.name,
                priority: file.priority || false,
                id: uuidv4(),
              }));
              uploadedFiles[fieldName] = formattedFiles;
            }
          }
        }

        // 5. Объединение старых и новых файлов с сохранением уникального ID
        const mergedFiles = Object.keys({
          ...currentFiles,
          ...uploadedFiles,
        }).reduce((acc, key) => {
          const existingFiles = currentFiles[key] || [];
          const newFilesArray = uploadedFiles[key] || [];
          acc[key] = [
            ...existingFiles.map((file) => ({
              url: file.url,
              name: file.name,
              priority: file.priority || false,
              id: file.id || uuidv4(),
            })),
            ...newFilesArray,
          ].filter((file) => file.url !== undefined);
          return acc;
        }, {});

        // 6. Формирование окончательных данных для обновления
        const finalData = {
          ...rootData,
          files: mergedFiles,
        };

        // Удаление undefined значений из finalData
        const cleanData = (data) =>
          JSON.parse(
            JSON.stringify(data, (key, value) =>
              value === undefined ? null : value
            )
          );

        const cleanedFinalData = cleanData(finalData);

        // 7. Обновление документа в Firestore
        await updateDoc(docRef, cleanedFinalData);

        // 8. Получение данных в коллекции предварительного просмотра, если не передан idPreviewDocument
        if (!idPreviewDocument) {
          console.log("idPreviewDocument не передан, начинаем поиск...");

          // Изменяем на использование getDocs вместо collection().get()
          const querySnapshot = await getDocs(
            collection(db, ...previewCollectionPath.split("."))
          );

          console.log(
            "Получены документы из previewCollectionPath:",
            previewCollectionPath
          );
          console.log("Количество документов в коллекции:", querySnapshot.size);

          for (const doc of querySnapshot.docs) {
            const data = doc.data().data || [];
            console.log(
              `Обрабатываем документ с ID: ${doc.id}, количество элементов в data: ${data.length}`
            );

            const foundItem = data.find(
              (item) => item.idRootDocument === idRootDocument
            );

            console.log("foundItem", foundItem, idRootDocument);

            if (foundItem) {
              console.log(
                `Найден элемент с idRootDocument: ${idRootDocument} в документе с ID: ${doc.id}`
              );
              idPreviewDocument = doc.id;
              break;
            }
          }

          if (!idPreviewDocument) {
            console.error(
              `idPreviewDocument не найден для idRootDocument: ${idRootDocument}`
            );
            throw new Error(
              `idPreviewDocument не найден для idRootDocument: ${idRootDocument}`
            );
          } else {
            console.log(
              `idPreviewDocument успешно найден: ${idPreviewDocument}`
            );
          }
        }

        // 9. Обновление документа предварительного просмотра
        const previewDocRef = doc(
          db,
          ...previewCollectionPath.split("."),
          idPreviewDocument
        );
        const previewDocSnap = await getDoc(previewDocRef);

        if (!previewDocSnap.exists()) {
          throw new Error(
            `Документ с ID ${idPreviewDocument} в коллекции preview не найден.`
          );
        }

        let previewDataArray = previewDocSnap.data().data || [];
        const itemIndex = previewDataArray.findIndex(
          (item) => item.idRootDocument === idRootDocument
        );

        if (itemIndex === -1) {
          throw new Error(
            `Элемент с idRootDocument ${idRootDocument} не найден в preview коллекции.`
          );
        }

        // Удаление редактируемого объекта из массива
        previewDataArray.splice(itemIndex, 1);

        // Сохранение обновленного массива данных в Firestore перед добавлением нового объекта
        await updateDoc(previewDocRef, { data: previewDataArray });

        // Используем previewData из аргумента
        previewData.idPreviewDocument = idPreviewDocument;
        previewData.idRootDocument = idRootDocument;
        previewData.createdat = Date.now();
        previewData.lastUpdated = Date.now();

        // 10. Оценка размера нового объекта и проверка на доступное место
        const objectSize = new Blob([JSON.stringify(previewData)]).size;
        const totalCurrentSize = previewDataArray.reduce(
          (acc, item) => acc + new Blob([JSON.stringify(item)]).size,
          0
        );

        const newTotalSize = totalCurrentSize + objectSize;
        const maxSize = 1024 * 1024; // 1 MB

        let idDocumentToSave;
        let newDocumentSize;

        if (newTotalSize <= maxSize) {
          // Обновление в текущем документе
          previewDataArray.push(previewData);
          newDocumentSize = newTotalSize;
          idDocumentToSave = idPreviewDocument;
        } else {
          // Создание нового документа для сохранения
          const newOriginalDocRef = doc(
            collection(db, ...previewCollectionPath.split("."))
          );
          idDocumentToSave = newOriginalDocRef.id;
          previewData.idPreviewDocument = idDocumentToSave;
          newDocumentSize = objectSize;
          previewDataArray = [previewData];
        }

        // 11. Обновление документа в Firestore
        const originalDocRef = doc(
          collection(db, ...previewCollectionPath.split(".")),
          idDocumentToSave
        );
        await setDoc(
          originalDocRef,
          {
            data: previewDataArray,
            documentSize: newDocumentSize,
          },
          { merge: true }
        );

        // 12. Обновление lastUpdatedDocument в документе `countries` в коллекции `catalog`
        const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
        await setDoc(
          languageDocRef,
          {
            lastUpdatedDocument: {
              lastUpdatedDocumentId: idDocumentToSave,
              documentSize: newDocumentSize,
            },
          },
          { merge: true }
        );

        return {
          previewDocumentData: previewData,
          rootDocumentData: cleanedFinalData,
          rootCollectionPath,
          previewCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          loadingStateName,
          errorSatetName,
        };
      } catch (error) {
        console.error(
          `Ошибка при попытке обновления:${attempt + 1} обновления:`,
          error
        );

        if (RETRYABLE_ERRORS.includes(error.code)) {
          attempt++;
          console.log(
            `Попытка повторного запроса ${attempt} из ${MAX_RETRIES}`
          );
          if (attempt >= MAX_RETRIES) {
            const errorMessage = interpretErrorV4(error.code);
            return rejectWithValue({ errorMessage });
          }
        } else {
          const errorMessage = interpretErrorV4(error.code);
          return rejectWithValue({ errorMessage });
        }
      }
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   arrayUnion,
//   getDoc,
//   deleteDoc,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { getIconFileUrl } from "../fetchCatalogData/utils";
// import { v4 as uuidv4 } from "uuid";
// import interpretAuthErrorV2 from "../../../../../functions/interpretAuthErrorV2";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// // Максимальное количество попыток
// const MAX_RETRIES = 3;

// // Список кодов ошибок, для которых будут выполняться повторные попытки
// const RETRYABLE_ERRORS = [
//   "unavailable", // Firestore backend не доступен
//   "deadline-exceeded", // Превышено время ожидания запроса
//   "internal", // Внутренняя ошибка Firestore
//   "failed-precondition", // Некорректные пред условия
//   "network-error", // Ошибка сети
// ];

// export const updateDataThunkV4 = createAsyncThunk(
//   "countries/updateDataThunkV4",
//   async (
//     {
//       rootData,
//       previewData,
//       newFiles = {},
//       idPreviewDocument,
//       idRootDocument,
//       rootCollectionPath,
//       previewCollectionPath,
//       metadataDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       loadingStateName,
//       errorSatetName,
//     },
//     { rejectWithValue }
//   ) => {
//     let attempt = 0; // Счетчик попыток

//     while (attempt < MAX_RETRIES) {
//       try {
//         // 1. Получение ссылки на документ корневой коллекции
//         const docRef = doc(
//           db,
//           ...rootCollectionPath.split("."),
//           idRootDocument
//         );

//         // 2. Проверка наличия документа перед обновлением
//         const docSnap = await getDoc(docRef);
//         if (!docSnap.exists()) {
//           return rejectWithValue(
//             `Document with ID ${idRootDocument} does not exist.`
//           );
//         }

//         // 3. Получение текущих файлов из Firestore
//         const currentFiles = docSnap.data().files || {};

//         // 4. Загрузка новых файлов в Firebase Storage и получение URL
//         const uploadedFiles = {};

//         if (newFiles && Object.keys(newFiles).length > 0) {
//           for (const [fieldName, fileArray] of Object.entries(newFiles)) {
//             if (fileArray && fileArray.length > 0) {
//               const uploaded = await uploadFilesToFirebase(
//                 `files/${idRootDocument}/${fieldName}`,
//                 fileArray
//               );

//               // Преобразуем загруженные файлы в нужный формат
//               const formattedFiles = uploaded.map((file) => ({
//                 url: file.url,
//                 name: file.name,
//                 priority: file.priority || false,
//                 id: uuidv4(),
//               }));

//               // Добавляем загруженные файлы в объект uploadedFiles с соответствующим именем поля
//               uploadedFiles[fieldName] = formattedFiles;
//             }
//           }
//         }

//         // 5. Объединение старых и новых файлов с сохранением уникального ID
//         const mergedFiles = Object.keys({
//           ...currentFiles,
//           ...uploadedFiles,
//         }).reduce((acc, key) => {
//           const existingFiles = currentFiles[key] || [];
//           const newFilesArray = uploadedFiles[key] || [];
//           acc[key] = [
//             ...existingFiles.map((file) => ({
//               url: file.url,
//               name: file.name,
//               priority: file.priority || false,
//               id: file.id || uuidv4(),
//             })),
//             ...newFilesArray,
//           ].filter((file) => file.url !== undefined); // Удаляем файлы с undefined URL
//           return acc;
//         }, {});

//         // 6. Формирование окончательных данных для обновления
//         const finalData = {
//           ...rootData,
//           files: mergedFiles,
//         };

//         // Удаление undefined значений из finalData
//         const cleanData = (data) =>
//           JSON.parse(
//             JSON.stringify(data, (key, value) =>
//               value === undefined ? null : value
//             )
//           );

//         const cleanedFinalData = cleanData(finalData);

//         // Удаление полей idRootDocument и lastUpdated перед обновлением в Firestore
//         const {
//           idRootDocument: _,
//           lastUpdated: __,
//           ...finalDataToSave
//         } = cleanedFinalData;

//         // 7. Обновление документа в Firestore
//         await updateDoc(docRef, finalDataToSave);

//         // 8. Получение данных в коллекции предварительного просмотра
//         const previewDocRef = doc(
//           db,
//           ...previewCollectionPath.split("."),
//           idPreviewDocument
//         );
//         const previewDocSnap = await getDoc(previewDocRef);

//         if (!previewDocSnap.exists()) {
//           throw new Error(
//             `Документ с ID ${idPreviewDocument} в коллекции preview не найден.`
//           );
//         }

//         let previewDataArray = previewDocSnap.data().data || [];
//         const itemIndex = previewDataArray.findIndex(
//           (item) => item.idRootDocument === idRootDocument
//         );

//         if (itemIndex === -1) {
//           throw new Error(
//             `Элемент с idRootDocument ${idRootDocument} не найден в preview коллекции.`
//           );
//         }

//         // Удаление редактируемого объекта из массива
//         previewDataArray.splice(itemIndex, 1);

//         // Сохранение обновленного массива данных в Firestore перед добавлением нового объекта
//         await updateDoc(previewDocRef, { data: previewDataArray });

//         // Используем previewData из аргумента
//         previewData.idPreviewDocument = idPreviewDocument;
//         previewData.idRootDocument = idRootDocument;
//         previewData.createdat = Date.now();
//         previewData.lastUpdated = Date.now();

//         // 10. Оценка размера нового объекта и проверка на доступное место
//         const objectSize = new Blob([JSON.stringify(previewData)]).size;
//         const totalCurrentSize = previewDataArray.reduce(
//           (acc, item) => acc + new Blob([JSON.stringify(item)]).size,
//           0
//         );

//         const newTotalSize = totalCurrentSize + objectSize;
//         const maxSize = 1024 * 1024; // 1 MB

//         let idDocumentToSave;
//         let newDocumentSize;

//         if (newTotalSize <= maxSize) {
//           // Обновление в текущем документе
//           previewDataArray.push(previewData);
//           newDocumentSize = newTotalSize;
//           idDocumentToSave = idPreviewDocument;
//         } else {
//           // Создание нового документа для сохранения
//           const newOriginalDocRef = doc(
//             collection(db, ...previewCollectionPath.split("."))
//           );
//           idDocumentToSave = newOriginalDocRef.id;
//           previewData.idPreviewDocument = idDocumentToSave; // Назначаем ID для нового preview документа
//           newDocumentSize = objectSize; // Только размер нового объекта
//           previewDataArray = [previewData]; // Новый массив данных с обновленным объектом
//         }

//         // 11. Обновление документа в Firestore
//         const originalDocRef = doc(
//           collection(db, ...previewCollectionPath.split(".")),
//           idDocumentToSave
//         );
//         await setDoc(
//           originalDocRef,
//           {
//             data: previewDataArray,
//             documentSize: newDocumentSize, // Обновление размера документа
//           },
//           { merge: true }
//         );

//         // 12. Обновление lastUpdatedDocument в документе `countries` в коллекции `catalog`
//         const languageDocRef = doc(db, ...metadataDocumentPath.split(".")); // Путь обновлен

//         await setDoc(
//           languageDocRef,
//           {
//             lastUpdatedDocument: {
//               lastUpdatedDocumentId: idDocumentToSave,
//               documentSize: newDocumentSize,
//             },
//           },
//           { merge: true }
//         );

//         return {
//           previewDocumentData: previewData,
//           rootDocumentData: cleanedFinalData,
//           rootCollectionPath,
//           previewCollectionPath,
//           rootCurrentDocumentState,
//           previewCurrentDocumenState,
//           loadingStateName,
//           errorSatetName,
//         };
//       } catch (error) {
//         console.error(
//           `Ошибка при попытке ${attempt + 1} обновления страны:`,
//           error
//         );

//         if (RETRYABLE_ERRORS.includes(error.code)) {
//           attempt++;
//           console.log(
//             `Попытка повторного запроса ${attempt} из ${MAX_RETRIES}`
//           );
//           if (attempt >= MAX_RETRIES) {
//             const errorMessage = interpretErrorV4(error.code);
//             return rejectWithValue({ errorMessage });
//           }
//         } else {
//           const errorMessage = interpretErrorV4(error.code);
//           return rejectWithValue({ errorMessage });
//         }
//       }
//     }
//   }
// );
