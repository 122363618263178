import React, { useEffect, useState } from "react";
import AddData from "../WidgetsProfile/AddData/AddData";
import WidgetRentalPointsList from "./WidgetRentalPointsList";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetModerationUserPreview from "./WidgetModerationUserPreview";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPointDetailModeration from "./WindowRentalPointDetailModeration";
import WidgetUserModeration from "./WidgetUserModeration";
import WidgetModeratorMessages from "./WidgetModeratorMessages";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowUserDetailModeration = ({ onClose }) => {
  const dispatch = useDispatch();

  const { loading, tempRootData, tempPreviewData, rootNewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  /** LOADING */
  const [
    loading_moderation_rootUserCurrentData,
    setLoading_moderation_rootUserCurrentData,
  ] = useState(false);
  /** */
  // const { selectedUserRentalPoints, selectedUser, isLoadingBikes } =
  //   useSelector((state) => state.catalogSliceV4);

  // const [rootUserId, setRootUserId] = useState(null);
  // const [previewUser, setPreviewUser] = useState({});
  const [rentalPointsList, setRentalPointsList] = useState([]);
  const [foundPreviewUser, setFoundPreviewUser] = useState(null);

  const [selectedIdUserModeration, setSelectedIdUserModeration] =
    useState(null);

  console.log("selectedIdUserModeration", selectedIdUserModeration);

  const [
    windowRentalPointDetailModeration,
    setWindowRentalPointDetailModeration,
  ] = useState(false);

  const [
    users_admin_subscribe_previewData,
    setUsers_admin_subscribe_previewData,
  ] = useState([]);

  // const [loading_fetchRootUserData, setLoading_fetchRootUserData] =
  //   useState(false);

  useEffect(() => {
    setSelectedIdUserModeration(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        .idRootDocument || null
    );
    setUsers_admin_subscribe_previewData(
      tempPreviewData?.users_admin_subscribe_previewData || null
    );
  }, [
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
    tempPreviewData?.users_admin_subscribe_previewData,
  ]);

  useEffect(() => {
    //Выполнеяется поиск по users_admin_subscribe_previewData т.к это данные подписки которые обновляются динамически
    const foundPreviewUser = users_admin_subscribe_previewData.find(
      (user) => user.idRootDocument === selectedIdUserModeration
    );
    setFoundPreviewUser(foundPreviewUser);
  }, [users_admin_subscribe_previewData, selectedIdUserModeration]);

  useEffect(() => {
    setLoading_moderation_rootUserCurrentData(
      loading?.loading_moderation_rootUserCurrentData || false
    );
  }, [loading?.loading_moderation_rootUserCurrentData]);

  const handleClose = () => {
    setWindowRentalPointDetailModeration(false);
  };

  const handleCreateRentalPoint = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "rentalPoint_create_createRerntalPoint",
        data: rootNewData.rootRentalPointData,
      })
    );
    setWindowRentalPointDetailModeration(true);
  };

  return (
    <>
      <PopupDirrectory
        isOpen={windowRentalPointDetailModeration}
        onClose={handleClose}
        title="Create rental point moderation"
      >
        <WindowRentalPointDetailModeration
          mode="moderatorAdded"
          onClose={handleClose}
        />
      </PopupDirrectory>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <ContentBox
          position="start"
          isLoading={loading_moderation_rootUserCurrentData}
        >
          <WidgetModerationUserPreview userPreview={foundPreviewUser} />
        </ContentBox>

        <ContentBox>
          <AddData
            onClick={handleCreateRentalPoint}
            icon={<span className="material-symbols-outlined">add</span>}
            title="Create rental point"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
          {rentalPointsList && rentalPointsList.length > 0 && (
            <WidgetRentalPointsList
              mode="moderation"
              rentalPointsList={rentalPointsList}
            />
          )}
        </ContentBox>

        <ContentBox>
          <WidgetModeratorMessages
          // selectedUser={selectedUser}
          // users={users}
          />
        </ContentBox>

        <ContentBox position="end">
          <WidgetUserModeration
            // selectedUser={selectedUser}
            onClose={onClose}
            mode="moderatiom"
          />
        </ContentBox>
      </div>
    </>
  );
};

export default WindowUserDetailModeration;
