import React, { useEffect, useState } from "react";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import { useDispatch, useSelector } from "react-redux";
import { updateDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../../WidgetsProfile/Buttons/Button";

const WindowUploadFiles = ({ onClose }) => {
  const dispatch = useDispatch();

  const { loading, tempRootData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [uId, setUId] = useState(null);
  const [userAvatarNewFiles, setUserAvatarNewFiles] = useState([]);
  const [userAvatarFiles, setUserAvatarFiles] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const [isSaveActive, setIsSaveActive] = useState(false); // Управление активностью кнопки

  /** LOADING */
  const [loading_userAvatarFiles, setLoading_userAvatarFiles] = useState(false);

  const [
    loading_user_auth_rootCurrentData,
    setLoading_user_auth_rootCurrentData,
  ] = useState(false);

  /** Reset Selected Files */
  const [resetSelectedFiles, setResetSelectedFiles] = useState(false);

  /** Update Loading States */
  useEffect(() => {
    setLoading_userAvatarFiles(loading?.loading_userAvatarFiles || false);

    setLoading_user_auth_rootCurrentData(
      loading?.loading_user_auth_rootCurrentData || false
    );
  }, [
    loading.loading_userAvatarFiles,
    loading.loading_updateUserData,
    loading?.loading_user_auth_rootCurrentData,
  ]);

  /** Update User Data */
  useEffect(() => {
    setCurrentUser(tempRootData?.user_auth_rootCurrentData);
    setUId(tempRootData?.user_auth_rootCurrentData?.id);
    setUserAvatarFiles(
      tempRootData?.user_auth_rootCurrentData?.files?.userAvatarFiles || []
    );
  }, [tempRootData.user_auth_rootCurrentData]);

  /** Активность кнопки сохранения на основе изменений в файлах */
  useEffect(() => {
    setIsSaveActive(userAvatarNewFiles.length > 0); // Активируем кнопку только при наличии новых файлов
  }, [userAvatarNewFiles]);

  /** Handle Update Fields */
  const handleUpdateFields = async () => {
    const rootUserData = {
      ...currentUser,
    };

    const priorityFile = userAvatarFiles.find((file) => file.priority === true);

    const previewUserData = {
      fileUrl: priorityFile ? priorityFile.url : "",
    };

    try {
      const resultAction = await dispatch(
        updateDataThunkV4({
          // Указываем id preview документа который будем менять
          idPreviewDocument: null,
          // Указываем id root Элемента
          idRootDocument: uId,
          // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
          rootData: rootUserData,
          previewData: previewUserData,
          // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
          newFiles: {
            userAvatarFiles: userAvatarNewFiles,
            /* Другие массивы файлов. Массивов файлов может быть несколько*/
          },
          // Путь до коллекции указывается из НЕ четного количества вложенностей
          rootCollectionPath: "users",
          previewCollectionPath: "usersPreview",
          // Путь до документа указывается из ЧЕТНОГО количества вложенностей
          metadataDocumentPath: "usersPreview.metadata",
          /*************/
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          // previewCurrentDocumenState: "user_profile_previewCurrentData",
          // Эти состояния будут отображать состояния загрузки и ошибки
          /*************/
          loadingStateName: "loading_user_auth_rootCurrentData",
          errorSatetName: "currentError",
        })
      );

      if (updateDataThunkV4.fulfilled.match(resultAction)) {
        console.log("Данные успешно обновлены:", resultAction.payload);
        setResetSelectedFiles(true); // Сбрасываем выбранные файлы
        // onClose(); // Закомментируйте, если не хотите закрывать окно сразу
      } else {
        console.error("Ошибка обновления данных:", resultAction.error.message);
        // onClose();
      }
    } catch (error) {
      console.error("Ошибка вызова thunk:", error);
      // onClose();
    }
  };

  return (
    <div
      style={{
        paddingBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <WidgetUploadFilesV4
          isLoading={
            loading_userAvatarFiles || loading_user_auth_rootCurrentData
          }
          id="userAvatarFiles2"
          title="Add user avater"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          files={setUserAvatarNewFiles}
          resetFiles={resetSelectedFiles} // Передаем флаг сброса
          setResetFiles={setResetSelectedFiles} // Передаем функцию для сброса флага
        />
        <WidgetListFileV4
          isLoading={
            loading_userAvatarFiles || loading_user_auth_rootCurrentData
          }
          // Передаём данные для отображения
          viewFiles={userAvatarFiles}
          // Поле в котором находится массив с файлами
          fieldName="files.userAvatarFiles"
          // Путь до коллекции указывается из НЕ четного количества вложенностей
          rootCollectionPath="users"
          previewCollectionPath="usersPreview"
          // Путь до документа указывается из ЧЕТНОГО количества вложенностей
          metadataDocumentPath="usersPreview.metadata"
          // Редактируемые документы
          idPreviewDocument={null}
          idRootDocument={uId}
          // Динамические состояния загрузки
          loadingStateName="loading_userAvatarFiles"
          errorSatetName="error_userAvatarFiles"
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState="user_auth_rootCurrentData"
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState="user_profile_previewCurrentData"
          // Режим
          mode="edit"
        />

        <ButtonsBar>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
              }}
            >
              <Button
                type="small"
                active={true}
                label="Cancel"
                onClick={onClose}
                color="--color-black"
              />
              <Button
                type="small"
                active={isSaveActive}
                label="Save"
                onClick={handleUpdateFields}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Please confirm that you want to update the user information.
                  </h1>
                }
              />
            </div>
          </div>
        </ButtonsBar>
      </ContentBox>
    </div>
  );
};

export default WindowUploadFiles;

// import React, { useEffect, useState } from "react";
// import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
// import WindgetUploadFiles from "../../WidgetsProfile/UploadFiles/WindgetUploadFiles";
// import WidgetListFile from "../../WidgetsProfile/UploadFiles/WidgetListFile";
// import { subscribeToDocument } from "../../../../../../services/firebase/crudFirestore";
// import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
// import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
// import { useDispatch, useSelector } from "react-redux";
// import { updateDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
// import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";
// import Button from "../../WidgetsProfile/Buttons/Button";

// const WindowUploadFiles = ({
//   collectionName,
//   documentId,
//   fieldName,
//   onClose,
// }) => {
//   const dispatch = useDispatch();

//   const { loading, tempRootData, tempPreviewData } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   const [files, setFiles] = useState([]);
//   const [uId, setUId] = useState(null);
//   const [userAvatarNewFiles, setUserAvatarNewFiles] = useState([]);
//   const [userAvatarFiles, setUserAvatarFiles] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);

//   /** LOADING */

//   const [loading_userAvatarFiles, setLoading_userAvatarFiles] = useState(false);
//   const [loading_updateUserData, setLoading_updateUserData] = useState(false);

//   /** */

//   /** LOADING */

//   useEffect(() => {
//     setLoading_userAvatarFiles(loading?.loading_userAvatarFiles || false);
//     setLoading_updateUserData(loading?.loading_updateUserData || false);
//   }, [loading.loading_userAvatarFiles, loading.loading_updateUserData]);

//   /** */
//   useEffect(() => {
//     setCurrentUser(tempRootData?.user_auth_rootCurrentData);
//     setUId(tempRootData?.user_auth_rootCurrentData?.id);
//     setUserAvatarFiles(
//       tempRootData?.user_auth_rootCurrentData?.files?.userAvatarFiles || []
//     );
//   }, [tempRootData.user_auth_rootCurrentData]);

//   const getNestedField = (obj, path) => {
//     return path.split(".").reduce((acc, part) => acc && acc[part], obj);
//   };

//   useEffect(() => {
//     const unsubscribe = subscribeToDocument(
//       collectionName,
//       documentId,
//       null,
//       async (data) => {
//         setFiles(data ? getNestedField(data, fieldName) : []);
//       }
//     );
//     return () => {
//       console.log("unsubscribe");
//       unsubscribe();
//     };
//   }, [documentId, fieldName, collectionName]);

//   const handleUpdateFields = () => {
//     const rootUserData = {
//       ...currentUser,
//     };

//     const previewUserData = {
//       avatarUrl: userAvatarFiles.find((file) => file.priority === true),
//     };
//     try {
//       const resultAction = dispatch(
//         updateDataThunkV4({
//           // Указываем id preview документа который будем менять
//           idPreviewDocument: null,
//           // Указываем id root Элемента
//           idRootDocument: uId,
//           // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
//           // updatedData: rootCountryData,

//           rootData: rootUserData,
//           previewData: previewUserData,

//           // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
//           newFiles: {
//             userAvatarFiles: userAvatarNewFiles,
//             /* Другие массивы файлов. Массивов файлов может быть несколько*/
//           },
//           // Путь до коллекции указывается из НЕ четного колличества вложенностей
//           rootCollectionPath: "users",
//           previewCollectionPath: "usersPreview",
//           // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
//           metadataDocumentPath: "usersPreview.metadata",
//           /*************/
//           // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
//           rootCurrentDocumentState: "user_auth_rootCurrentData",
//           // Сохраняет в state МАССИВ объектов как результат выполнения thunk
//           previewCurrentDocumenState: "user_profile_previewCurrentData",
//           // Эти состояния будут отображать состояния загрузки и ошибки
//           /*************/
//           loadingStateName: "loading_updateUserData",
//           errorSatetName: "error_updateUserData",
//         })
//       );
//       if (updateDataThunkV4.fulfilled.match(resultAction)) {
//         console.log("Данные успешно обновлены:", resultAction.payload);
//         onClose();
//       } else {
//         console.error("Ошибка обновления данных:", resultAction.error.message);
//         onClose();
//       }
//     } catch (error) {
//       console.error("Ошибка вызова thunk:", error);
//       onClose();
//     }
//   };

//   return (
//     <div
//       style={{
//         paddingBottom: "72px",
//       }}
//     >
//       <ContentBox position="start">
//         <WidgetListFileV4
//           isLoading={loading_updateUserData || loading_userAvatarFiles}
//           // Передаём данные для отображения
//           viewFiles={userAvatarFiles}
//           // Поле в котором находится массив с файлами
//           fieldName="files.userAvatarFiles"
//           // Путь до коллекции указывается из НЕ четного колличества вложенностей
//           rootCollectionPath="users"
//           previewCollectionPath="usersPreview"
//           // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
//           metadataDocumentPath="usersPreview.metadata"
//           // Редактируемые документы
//           idPreviewDocument={null}
//           idRootDocument={uId}
//           // Динамические состояния загрузки
//           loadingStateName="loading_userAvatarFiles"
//           errorSatetName="error_userAvatarFiles"
//           // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
//           rootCurrentDocumentState="user_auth_rootCurrentData"
//           // Сохраняет в state МАССИВ объектов как результат выполнения thunk
//           previewCurrentDocumenState="user_profile_previewCurrentData"
//           // Режим
//           mode="edit"
//         />

//         <WidgetUploadFilesV4
//           isLoading={loading_updateUserData || loading_userAvatarFiles}
//           id="userAvatarFiles2"
//           title="Add photo country"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//           files={setUserAvatarNewFiles}
//         />

//         {/* <WindgetUploadFiles
//         collectionName={collectionName}
//         documentId={documentId}
//         fieldName={fieldName}
//       />

//       {files?.length > 0 && (
//         <WidgetListFile
//           collectionName={collectionName}
//           documentId={documentId}
//           fieldName={fieldName}
//           files={files}
//           mode="edit"
//         />
//       )} */}
//         <ButtonsBar>
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               gap: "16px",
//             }}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 display: "grid",
//                 gridTemplateColumns: "repeat(2, 1fr)",
//                 gap: "16px",
//               }}
//             >
//               <Button
//                 type="small"
//                 active={true}
//                 label="Cancel"
//                 onClick={onClose}
//                 color="--color-black"
//               />
//               <Button
//                 type="small"
//                 active={true}
//                 label="Save"
//                 onClick={handleUpdateFields}
//                 allert={
//                   <h1
//                     style={{
//                       padding: "80px",
//                       textAlign: "center",
//                     }}
//                   >
//                     Please confirm that you want to update the user information.
//                   </h1>
//                 }
//               />
//             </div>
//           </div>
//         </ButtonsBar>
//       </ContentBox>
//     </div>
//   );
// };

// export default WindowUploadFiles;
