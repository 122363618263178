import React, { useState, useEffect } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowEditPhoneModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [newPhoneNumbers, setNewPhoneNumbers] = useState([]);
  const [removedPhoneNumbers, setRemovedPhoneNumbers] = useState([]); // Локальное состояние для удалённых номеров

  const currentUser = useSelector((state) => state.catalogSliceV4.currentUser);
  const { newRentalPoint, selectedRentalPoint } = useSelector(
    (state) => state.catalogSliceV4
  );

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setPhoneNumbers(newRentalPoint?.contacts?.phoneNumbers || []);
    } else if (mode === "moderation") {
      setPhoneNumbers(selectedRentalPoint?.contacts?.phoneNumbers || []);
    }
  }, [mode, newRentalPoint, selectedRentalPoint]);

  // Добавление нового телефонного номера в локальное состояние
  const handleAddPhone = () => {
    const phoneObject = {
      key: Date.now().toString(),
      phone,
      moderatorId: mode === "moderation" ? currentUser.id : null,
    };
    setNewPhoneNumbers([...newPhoneNumbers, phoneObject]);
    setPhone("");
  };

  // Удаление нового добавленного телефона из локального состояния
  const handleRemovePhone = (index) => {
    setNewPhoneNumbers(newPhoneNumbers.filter((_, i) => i !== index));
  };

  // Удаление загруженного телефона из локального состояния, но не из Redux
  const handleRemoveLoadedPhone = (phoneObject) => {
    setPhoneNumbers(
      phoneNumbers.filter((phone) => phone.key !== phoneObject.key)
    );
    setRemovedPhoneNumbers([...removedPhoneNumbers, phoneObject]);
  };

  const handleApply = () => {
    // Объединение оставшихся номеров с новыми
    const updatedPhoneNumbers = [...phoneNumbers, ...newPhoneNumbers];

    // Обновление состояния в Redux
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState:
          "rentalPoint_create_createRerntalPoint.contacts.phoneNumbers",
        data: updatedPhoneNumbers,
      })
    );

    // Если нужно обработать удалённые номера
    // dispatch(removeContactsPhoneNumberModeration(removedPhoneNumbers));

    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <InputText
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              label="Phone number"
              description="Enter the phone number."
            />
            <div style={{ width: "auto" }}>
              <Button
                label="Add"
                active={phone ? true : false}
                onClick={handleAddPhone}
              />
            </div>
          </div>
        </ContentBox>

        {(phoneNumbers.length > 0 || newPhoneNumbers.length > 0) && (
          <ContentBox>
            <ul
              style={{
                width: "100%",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {/* Список существующих телефонов */}
              {phoneNumbers.map((phoneObj) => (
                <li
                  key={phoneObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{phoneObj.phone || "No phone number"}</h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemoveLoadedPhone(phoneObj)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this phone number?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}

              {/* Список новых телефонов */}
              {newPhoneNumbers.map((phoneObj, index) => (
                <li
                  key={phoneObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{phoneObj.phone || "No phone number"}</h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemovePhone(index)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this phone number?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </ContentBox>
        )}
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            active={newPhoneNumbers.length > 0}
            label="Save"
            onClick={handleApply}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Please confirm that you want to update the user information.
              </h1>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditPhoneModeration;

// import React, { useState, useEffect } from "react";
// import InputText from "../WidgetsProfile/Inputs/InputText";
// import Button from "../WidgetsProfile/Buttons/Button";
// import { useDispatch, useSelector } from "react-redux";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import { removeContactsPhoneNumber } from "../../../../../reducersToolkit/rentalPointsSliiceV2";
// import {
//   removeContactsPhoneNumberModeration,
//   setContactsPhoneNumbersModeration,
// } from "../../../../../reducersToolkit/moderationSliceV2";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

// const WindowEditPhoneModeration = ({ mode, onClose }) => {
//   const dispatch = useDispatch();

//   const [phone, setPhone] = useState("");
//   const [phoneNumbers, setPhoneNumbers] = useState([]);
//   const [newPhoneNumbers, setNewPhoneNumbers] = useState([]);

//   const currentUser = useSelector((state) => state.catalogSliceV4.currentUser);

//   const { newRentalPoint, selectedRentalPoint } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   useEffect(() => {
//     if (mode === "moderatorAdded") {
//       setPhoneNumbers(newRentalPoint?.contacts?.phoneNumbers || []);
//     } else if (mode === "moderation") {
//       setPhoneNumbers(selectedRentalPoint?.contacts?.phoneNumbers || []);
//     }
//   }, [mode, newRentalPoint, selectedRentalPoint]);

//   const handleAddPhone = () => {
//     const phoneObject = {
//       key: Date.now().toString(),
//       phone,
//       moderatorId: mode === "moderation" ? currentUser.id : null,
//     };
//     setNewPhoneNumbers([...newPhoneNumbers, phoneObject]);
//     setPhone("");
//   };

//   const handleRemovePhone = (index) => {
//     setNewPhoneNumbers(newPhoneNumbers.filter((_, i) => i !== index));
//   };

//   const handleRemoveLoadedPhone = (phoneObject) => {
//     dispatch(
//       removeContactsPhoneNumberModeration({ mode: mode, key: phoneObject.key })
//     );
//   };

//   const handleApply = () => {
//     newPhoneNumbers.forEach((phoneObject) => {
//       dispatch(
//         setContactsPhoneNumbersModeration({ mode, phoneNumber: phoneObject })
//       );
//     });
//     onClose();
//   };

//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           // height: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           gap: "8px",
//           marginBottom: "72px",
//         }}
//       >
//         <ContentBox position="start">
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "flex-start",
//               gap: "16px",
//             }}
//           >
//             <InputText
//               value={phone}
//               onChange={(e) => setPhone(e.target.value)}
//               label="Phone number"
//               description="Enter the phone number."
//             />
//             <div
//               style={{
//                 width: "auto",
//               }}
//             >
//               <Button
//                 label="Add"
//                 active={phone ? true : false}
//                 onClick={handleAddPhone}
//               />
//             </div>
//           </div>
//         </ContentBox>
//         {(phoneNumbers.length > 0 || newPhoneNumbers.length > 0) && (
//           <ContentBox>
//             <ul
//               style={{
//                 width: "100%",
//                 listStyle: "none",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "8px",
//               }}
//             >
//               {phoneNumbers.map((phoneObj) => (
//                 <li
//                   key={phoneObj.key}
//                   style={{
//                     height: "56px",
//                     width: "100%",
//                     boxSizing: "border-box",
//                     borderRadius: "8px",
//                     backgroundColor: "var(--color-gray-100)",
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     padding: "0 8px 0 16px",
//                   }}
//                 >
//                   <h6>{phoneObj.phone || "No phone number"}</h6>
//                   <div
//                     style={{
//                       width: "auto",
//                     }}
//                   >
//                     <Button
//                       onClick={() => handleRemoveLoadedPhone(phoneObj)}
//                       allert={
//                         <h1
//                           style={{
//                             padding: "48px",
//                             textAlign: "center",
//                           }}
//                         >
//                           Are you sure you want to delete this phone number?
//                           Please confirm your action.
//                         </h1>
//                       }
//                       active={true}
//                       color="--color-gray-100"
//                       type="icon"
//                       icon={
//                         <span className="material-symbols-outlined">
//                           delete
//                         </span>
//                       }
//                     />
//                   </div>
//                 </li>
//               ))}
//               {newPhoneNumbers.map((phoneObj, index) => (
//                 <li
//                   key={phoneObj.key}
//                   style={{
//                     height: "56px",
//                     width: "100%",
//                     boxSizing: "border-box",
//                     borderRadius: "8px",
//                     backgroundColor: "var(--color-gray-100)",
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     padding: "0 8px 0 16px",
//                   }}
//                 >
//                   <h6>{phoneObj.phone || "No phone number"}</h6>
//                   <div
//                     style={{
//                       width: "auto",
//                     }}
//                   >
//                     <Button
//                       onClick={() => handleRemovePhone(index)}
//                       allert={
//                         <h1
//                           style={{
//                             padding: "48px",
//                             textAlign: "center",
//                           }}
//                         >
//                           Are you sure you want to delete this phone number?
//                           Please confirm your action.
//                         </h1>
//                       }
//                       active={true}
//                       color="--color-gray-100"
//                       type="icon"
//                       icon={
//                         <span className="material-symbols-outlined">
//                           delete
//                         </span>
//                       }
//                     />
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           </ContentBox>
//         )}

//         {/* <ContentBox position="end">
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             gap: "16px",
//             width: "100%",
//           }}
//         >
//           <Button
//             label="Cancel"
//             active={true}
//             color="--color-alarm"
//             onClick={onClose}
//           />
//           <Button
//             label="Apply"
//             active={newPhoneNumbers.length > 0 && true}
//             onClick={handleApply}
//           />
//         </div>
//       </ContentBox> */}
//       </div>
//       <ButtonsBar>
//         <div
//           style={{
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)",
//             gap: "16px",
//             width: "100%",
//           }}
//         >
//           <Button
//             type="small"
//             active={true}
//             label="Cancel"
//             color="--color-black"
//             onClick={onClose}
//             allert={
//               <h1
//                 style={{
//                   padding: "80px",
//                   textAlign: "center",
//                 }}
//               >
//                 Are you sure you want to leave the editing window?
//               </h1>
//             }
//           />

//           <Button
//             type="small"
//             active={newPhoneNumbers.length > 0 && true}
//             label="Save"
//             onClick={handleApply}
//             allert={
//               <h1
//                 style={{
//                   padding: "80px",
//                   textAlign: "center",
//                 }}
//               >
//                 Please confirm that you want to update the user information.
//               </h1>
//             }
//           />
//         </div>
//       </ButtonsBar>
//     </>
//   );
// };

// export default WindowEditPhoneModeration;
