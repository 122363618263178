import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowEditBasicInformationModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const {
    /** !!!Заменить!!! */
    selectedRentalPoint,
    /** */
    tempRootData,
    // tempData
  } = useSelector((state) => state.catalogSliceV4);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [newData, setNewData] = useState(null);

  useEffect(() => {
    setNewData({
      title: {
        titleOriginal: title,
      },

      description: {
        descriptionOriginal: description,
      },
    });
  }, [title, description]);

  useEffect(() => {
    if (mode === "moderation") {
      // setTitle(
      //   selectedRentalPoint.rentalPoint_new_basicInformation.title
      //     ?.titleOriginal || ""
      // );
      // setDescription(
      //   selectedRentalPoint.rentalPoint_new_basicInformation.description
      //     ?.descriptionOriginal || ""
      // );
    } else if (
      /** Заполнение полей формы текущими значениями при СОЗДАНИИ НОВОЙ RENTALPOINT */
      mode === "moderatorAdded"
    ) {
      setTitle(
        tempRootData?.rentalPoint_create_createRerntalPoint?.basicInformation
          .title?.titleOriginal || ""
      );
      setDescription(
        tempRootData?.rentalPoint_create_createRerntalPoint?.basicInformation
          .description?.descriptionOriginal || ""
      );
    }
  }, [
    selectedRentalPoint,
    tempRootData?.rentalPoint_create_createRerntalPoint?.basicInformation,
    mode,
  ]);

  const handleSetTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  /** Сохранение в стейт содержащий структуру данных rentalPoint информацию раздела "basicInformation" */
  const handleApply = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState:
          "rentalPoint_create_createRerntalPoint.basicInformation",
        data: newData,
      })
    );
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Rental point name"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={title}
            onChange={handleSetTitle}
          />
          <Textarea
            label="Description rental point"
            maxLength={500}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={description}
            onChange={handleSetDescription}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            active={true}
            label="Save"
            onClick={handleApply}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Please confirm that you want to update the user information.
              </h1>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditBasicInformationModeration;

// import React, { useEffect, useState } from "react";
// import InputText from "../WidgetsProfile/Inputs/InputText";
// import Textarea from "../WidgetsProfile/Textarea/Textarea";
// import Button from "../WidgetsProfile/Buttons/Button";
// import { useDispatch, useSelector } from "react-redux";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import { setBasicInformationModeration } from "../../../../../reducersToolkit/moderationSliceV2";

// const WindowEditBasicInformationModeration = ({ mode, onClose }) => {
//   const dispatch = useDispatch();

//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");

//   const currentUser = useSelector((state) => state.catalogSliceV4.currentUser);

//   const { selectedRentalPoint, newRentalPoint } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   useEffect(() => {
//     if (mode === "moderation") {
//       setTitle(selectedRentalPoint.rentalPoint_new_basicInformation.title.titleOriginal);
//       setDescription(
//         selectedRentalPoint.rentalPoint_new_basicInformation.description.descriptionOriginal
//       );
//     } else if (mode === "moderatorAdded") {
//       setTitle(newRentalPoint.rentalPoint_new_basicInformation.title.titleOriginal);
//       setDescription(
//         newRentalPoint.rentalPoint_new_basicInformation.description.descriptionOriginal
//       );
//     }
//   }, [selectedRentalPoint, newRentalPoint, mode]);

//   const handleSetTitle = (event) => {
//     setTitle(event.target.value);
//   };

//   const handleSetDescription = (event) => {
//     setDescription(event.target.value);
//   };

//   const handleApply = () => {
//     dispatch(
//       setBasicInformationModeration({
//         title,
//         description,
//         userId: currentUser.id,
//         mode,
//       })
//     );
//     onClose();
//   };

//   const handleCancel = () => {
//     onClose();
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         height: "100%",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         gap: "8px",
//       }}
//     >
//       <ContentBox position="start">
//         <InputText
//           label="Rental point name"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//           value={title}
//           onChange={handleSetTitle}
//         />
//         <Textarea
//           label="Description rental point"
//           maxLength={500}
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//           value={description}
//           onChange={handleSetDescription}
//         />
//       </ContentBox>

//       <ContentBox position="end">
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             gap: "16px",
//             width: "100%",
//           }}
//         >
//           <Button
//             label="Cancel"
//             active={true}
//             color="--color-alarm"
//             onClick={handleCancel}
//           />
//           <Button label="Apply" active={true} onClick={handleApply} />
//         </div>
//       </ContentBox>
//     </div>
//   );
// };

// export default WindowEditBasicInformationModeration;
