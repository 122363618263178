import React, { useEffect, useRef, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import {
  addAdditionalServiceModeration,
  deleteAdditionalServiceModeration,
  updateAdditionalServiceModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";
import Counter from "../WidgetsProfile/Counter/Counter";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowAditionalServiceDetailModeration = ({
  mode,
  onClose,
  selectedAditionalService,
}) => {
  const dispatch = useDispatch();

  const { tempRootData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [counterValues, setCounterValues] = useState(0);
  const prevCounterValues = useRef(counterValues);
  const [existingServices, setExistingServices] = useState([]);

  // const currentUser = useSelector((state) => state.catalogSliceV4.currentUser);

  let additionalService = {
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    price: price,
    id: selectedServiceId || Date.now().toString(),
    counter: counterValues,
    remove: "",
  };

  useEffect(() => {
    if (mode === "moderation" || mode === "moderatorAdded") {
      setTitle(
        tempData.rentalPoint_moderation_selectedAdditionalService?.title
          ?.titleOriginal || ""
      );
      setDescription(
        tempData.rentalPoint_moderation_selectedAdditionalService?.description
          ?.descriptionOriginal || ""
      );
      setPrice(
        tempData.rentalPoint_moderation_selectedAdditionalService?.price || null
      );
      setSelectedServiceId(
        tempData.rentalPoint_moderation_selectedAdditionalService?.id
      );
      setCounterValues(
        tempData.rentalPoint_moderation_selectedAdditionalService?.counter || 0
      );
    }
  }, [mode, tempData.rentalPoint_moderation_selectedAdditionalService]);

  useEffect(() => {
    setExistingServices(
      tempRootData?.rentalPoint_create_createRerntalPoint?.additionalServices ||
        []
    );
  }, [tempRootData?.rentalPoint_create_createRerntalPoint?.additionalServices]);

  const handleAddAdditionalService = () => {
    // const path = "rentalPoint_create_createRerntalPoint.additionalServices";

    const updatedServices = [...existingServices, additionalService];

    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState:
          "rentalPoint_create_createRerntalPoint.additionalServices",
        data: updatedServices,
      })
    );

    onClose();
  };

  const handleUpdateAdditionalService = () => {
    // const path = "rentalPoint_create_createRerntalPoint.additionalServices";

    // Ищем и обновляем нужную услугу в массиве
    const updatedServices = existingServices.map((service) =>
      service.id === selectedServiceId ? additionalService : service
    );

    // Отправляем обновленный массив в Redux
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState:
          "rentalPoint_create_createRerntalPoint.additionalServices",
        data: updatedServices,
      })
    );

    onClose(); // Закрываем окно после обновления
  };

  const handleDeleteService = () => {
    // const path = "rentalPoint_create_createRerntalPoint.additionalServices";

    // Ищем и обновляем поле remove для нужной услуги
    const updatedServices = existingServices.map((service) =>
      service.id === selectedServiceId
        ? { ...service, remove: Date.now() } // Обновляем поле remove с текущим timestamp
        : service
    );

    // Отправляем обновленный массив в Redux
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState:
          "rentalPoint_create_createRerntalPoint.additionalServices",
        data: updatedServices,
      })
    );

    onClose(); // Закрываем окно после удаления
  };

  const handleRestoreService = () => {
    const path = "rentalPoint_create_createRerntalPoint.additionalServices";

    // Ищем и обновляем услугу с нужным `selectedServiceId`, обнуляя поле `remove`
    const updatedServices = existingServices.map((service) => {
      if (service.id === selectedServiceId) {
        return { ...service, remove: 0 }; // Обнуляем поле remove
      }
      return service; // Оставляем остальные услуги без изменений
    });

    // Обновляем данные в Redux
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: path,
        data: updatedServices,
      })
    );

    onClose(); // Закрываем окно
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Service name"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />

          <Textarea
            label="Description aditional service"
            maxLength={500}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />

          <InputText
            label="Price"
            type="number"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(e) => setPrice(Number(e.target.value))}
            value={price}
          />

          <Counter
            maxValue={50}
            minValue={1}
            value={counterValues}
            onChange={(newCount) => {
              if (prevCounterValues.current !== newCount) {
                setCounterValues(newCount);
                prevCounterValues.current = newCount;
              }
            }}
            label="Number of additional services"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          {!selectedAditionalService ? (
            <>
              <Button
                type="small"
                active={true}
                label="Cancel"
                color="--color-black"
                onClick={onClose}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Are you sure you want to leave the editing window?
                  </h1>
                }
              />
              <Button
                label="Add"
                type="small"
                onClick={handleAddAdditionalService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Are you sure you want to save this information?</h1>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <p className="p-light">Service name: {description}</p>
                      <p className="p-light">
                        Description aditional service: {title}
                      </p>
                      <p className="p-light">
                        Price: {formatCurrencyUSD(price)}
                      </p>
                    </div>
                  </div>
                }
              />
            </>
          ) : selectedAditionalService.remove > 0 ? (
            <>
              <Button
                type="small"
                active={true}
                label="Cancel"
                color="--color-black"
                onClick={onClose}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Are you sure you want to leave the editing window?
                  </h1>
                }
              />
              <Button
                label="Restore"
                type="small"
                onClick={handleRestoreService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>
                      Are you sure you want to restore this additional service?
                    </h1>
                  </div>
                }
                color="--color-success"
              />
            </>
          ) : (
            <>
              <Button
                label="Remove"
                type="small"
                onClick={handleDeleteService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>
                      Are you sure you want to delete this additional service?
                    </h1>
                    <p className="p-light">
                      This action is irreversible, and you will lose all data
                      associated with this service.
                    </p>
                  </div>
                }
                color="--color-alarm"
              />

              <Button
                label="Update"
                type="small"
                onClick={handleUpdateAdditionalService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Are you sure you want to save this information?</h1>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <p className="p-light">Service name: {description}</p>
                      <p className="p-light">
                        Description aditional service: {title}
                      </p>
                      <p className="p-light">
                        Price: {formatCurrencyUSD(price)}
                      </p>
                    </div>
                  </div>
                }
              />
            </>
          )}
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowAditionalServiceDetailModeration;

// import React, { useEffect, useRef, useState } from "react";
// import InputText from "../WidgetsProfile/Inputs/InputText";
// import Textarea from "../WidgetsProfile/Textarea/Textarea";
// import Button from "../WidgetsProfile/Buttons/Button";
// import { useDispatch, useSelector } from "react-redux";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
// import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";
// import Counter from "../WidgetsProfile/Counter/Counter";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
// import { v4 as uuidv4 } from "uuid";

// const WindowAditionalServiceDetailModeration = ({
//   mode,
//   onClose,
//   selectedAditionalService,
// }) => {
//   const dispatch = useDispatch();
//   const { tempRootData } = useSelector((state) => state.catalogSliceV4); // Получаем tempRootData из state

//   const [description, setDescription] = useState("");
//   const [title, setTitle] = useState("");
//   const [price, setPrice] = useState("");
//   const [counterValues, setCounterValues] = useState(0);

//   const [localService, setLocalService] = useState({
//     title: {
//       titleOriginal: "",
//     },
//     description: {
//       descriptionOriginal: "",
//     },
//     price: 0,
//     id: uuidv4(), // Генерация уникального id для новой услуги
//     counter: 0,
//   });

//   useEffect(() => {
//     if (mode === "moderation" && selectedAditionalService) {
//       // Инициализируем локальное состояние на основе выбранной услуги
//       setLocalService({
//         title: {
//           titleOriginal: selectedAditionalService?.title?.titleOriginal,
//         },
//         description: {
//           descriptionOriginal:
//             selectedAditionalService?.description?.descriptionOriginal,
//         },
//         price: selectedAditionalService?.price,
//         id: selectedAditionalService?.id || uuidv4(),
//         counter: selectedAditionalService?.counter || 0,
//       });
//     }
//   }, [mode, selectedAditionalService]);

//   // Обработчик добавления услуги
//   const handleAddAdditionalService = () => {
//     const path = "rentalPoint_create_createRerntalPoint.additionalServices"; // Пример пути к дополнительным услугам
//     const existingServices =
//       tempRootData.rentalPoint_create_createRerntalPoint?.additionalServices ||
//       []; // Получаем текущие услуги из tempRootData

//     const updatedServices = [...existingServices, localService]; // Добавляем новую услугу в массив

//     // Отправляем обновленный массив в Redux
//     dispatch(
//       setCurrentRootData({
//         rootCurrentDocumentState: path,
//         data: updatedServices,
//       })
//     );

//     onClose(); // Закрываем окно после добавления
//   };

//   // Обработчик обновления услуги
//   const handleUpdateAdditionalService = () => {
//     const path = "rentalPoint_create_createRerntalPoint.additionalServices";
//     const existingServices =
//       tempRootData.rentalPoint_create_createRerntalPoint?.additionalServices ||
//       [];

//     const updatedServices = existingServices.map((service) =>
//       service.id === localService.id ? localService : service
//     );

//     dispatch(
//       setCurrentRootData({
//         rootCurrentDocumentState: path,
//         data: updatedServices,
//       })
//     );

//     onClose();
//   };

//   // Обработчик удаления услуги
//   const handleDeleteService = () => {
//     const path = "rentalPoint_create_createRerntalPoint.additionalServices";
//     const existingServices =
//       tempRootData.rentalPoint_create_createRerntalPoint?.additionalServices ||
//       [];

//     const updatedServices = existingServices.filter(
//       (service) => service.id !== localService.id
//     );

//     dispatch(
//       setCurrentRootData({
//         rootCurrentDocumentState: path,
//         data: updatedServices,
//       })
//     );

//     onClose();
//   };

//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           gap: "8px",
//           marginBottom: "72px",
//         }}
//       >
//         <ContentBox position="start">
//           <InputText
//             label="Service name"
//             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//             onChange={(e) =>
//               setLocalService((prev) => ({
//                 ...prev,
//                 title: { titleOriginal: e.target.value },
//               }))
//             }
//             value={localService.title.titleOriginal}
//           />

//           <Textarea
//             label="Description aditional service"
//             maxLength={500}
//             onChange={(e) =>
//               setLocalService((prev) => ({
//                 ...prev,
//                 description: { descriptionOriginal: e.target.value },
//               }))
//             }
//             value={localService.description.descriptionOriginal}
//           />

//           <InputText
//             label="Price"
//             type="number"
//             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//             onChange={(e) =>
//               setLocalService((prev) => ({
//                 ...prev,
//                 price: Number(e.target.value),
//               }))
//             }
//             value={localService.price}
//           />

//           <Counter
//             maxValue={50}
//             minValue={1}
//             value={localService.counter}
//             onChange={(newCount) =>
//               setLocalService((prev) => ({ ...prev, counter: newCount }))
//             }
//             label="Number of additional services"
//             description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
//           />
//         </ContentBox>
//       </div>
//       <ButtonsBar>
//         <div
//           style={{
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)",
//             gap: "16px",
//             width: "100%",
//           }}
//         >
//           {!selectedAditionalService ? (
//             <>
//               <Button
//                 type="small"
//                 active={true}
//                 label="Cancel"
//                 color="--color-black"
//                 onClick={onClose}
//                 allert={
//                   <h1
//                     style={{
//                       padding: "80px",
//                       textAlign: "center",
//                     }}
//                   >
//                     Are you sure you want to leave the editing window?
//                   </h1>
//                 }
//               />
//               <Button
//                 label="Add"
//                 type="small"
//                 onClick={handleAddAdditionalService}
//                 active={true}
//                 allert={
//                   <div
//                     style={{
//                       padding: "48px",
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       gap: "16px",
//                       textAlign: "center",
//                     }}
//                   >
//                     <h1>Are you sure you want to save this information?</h1>
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                         gap: "2px",
//                       }}
//                     >
//                       <p className="p-light">
//                         Service name: {localService.title.titleOriginal}
//                       </p>
//                       <p className="p-light">
//                         Description aditional service:
//                         {localService.description.descriptionOriginal}
//                       </p>
//                       <p className="p-light">
//                         Price: {formatCurrencyUSD(localService.price)}
//                       </p>
//                     </div>
//                   </div>
//                 }
//               />
//             </>
//           ) : (
//             <>
//               <Button
//                 label="Remove"
//                 type="small"
//                 onClick={handleDeleteService}
//                 active={true}
//                 allert={
//                   <div
//                     style={{
//                       padding: "48px",
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       gap: "16px",
//                       textAlign: "center",
//                     }}
//                   >
//                     <h1>
//                       Are you sure you want to delete this additional service?
//                     </h1>
//                     <p className="p-light">
//                       This action is irreversible, and you will lose all data
//                       associated with this service.
//                     </p>
//                   </div>
//                 }
//                 color="--color-alarm"
//               />

//               <Button
//                 label="Update"
//                 type="small"
//                 onClick={handleUpdateAdditionalService}
//                 active={true}
//                 allert={
//                   <div
//                     style={{
//                       padding: "48px",
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       gap: "16px",
//                       textAlign: "center",
//                     }}
//                   >
//                     <h1>Are you sure you want to save this information?</h1>
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                         gap: "2px",
//                       }}
//                     >
//                       <p className="p-light">
//                         Service name: {localService.title.titleOriginal}
//                       </p>
//                       <p className="p-light">
//                         Description aditional service:{" "}
//                         {localService.description.descriptionOriginal}
//                       </p>
//                       <p className="p-light">
//                         Price: {formatCurrencyUSD(localService.price)}
//                       </p>
//                     </div>
//                   </div>
//                 }
//               />
//             </>
//           )}
//         </div>
//       </ButtonsBar>
//     </>
//   );
// };

// export default WindowAditionalServiceDetailModeration;
