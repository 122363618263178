import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetOrdersList from "./WidgetOrdersList";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import Preloader from "../../../../UIElements/Preloader";

const WidgetOrders = ({ orders, isLoading }) => {
  const [orderType, setOrderType] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    if (orderType) {
      const filtered = orders?.filter(
        (order) => order.orderStatuses?.currentStatus === orderType
      );
      setFilteredOrders(filtered);
    }
  }, [orderType, orders]);

  return (
    <ContentBox>
      {!isLoading ? (
        <>
          <SegmentedControls
            options={[
              "new",
              "exchange",
              "in progress",
              "completed",
              "canceled",
            ]}
            onSelect="new"
            label="Orders"
            selected={setOrderType}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tincidunt justo odio, gravida tempus erat convallis id. Suspendisse maximus purus non tellus feugiat, ac laoreet diam feugiat."
          />

          {orderType && (
            <>
              <WidgetOrdersList filteredOrders={filteredOrders} />
            </>
          )}
        </>
      ) : (
        <Preloader />
      )}
    </ContentBox>
  );
};

export default WidgetOrders;
